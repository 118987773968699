import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RegisterCard } from "../components/RegisterCard";
import { TopAdminNav } from "../layouts/TopAdminNav";
import { useState, useEffect } from "react";
import BackButton from "../components/backButton";
import { Menu, MenuButton, MenuItem, SubMenu } from "@szhsin/react-menu";
import Swal from "sweetalert2";
import CustomModal from "../components/CustomModal/CustomModal";
import { EditStaffForm } from "../layouts/editForms/staff";
import { defaultUrl } from "../features/actions";
import toast from "react-hot-toast";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import Pagination from "../components/pagination";
import { IPaginationParams } from "../types/interfaces";
import loader from "../assets/loader.gif";
import { uploadImageToCloudinary } from "../utilities/importantFns";
import { makeFetchRequest, makePostRequest, makePutRequest } from "../utilities/common";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

export interface StaffDataType {
  id: string;
  firstname?: string;
  surname?: string;
  lastname?: string;
  othername?: string;
  gender?: string;
  role?: string;
  date_of_birth?: string;
  resumption_date?: string;
  email?: string;
  phone_number?: string;
  degree?: string;
  classrooms?: { id: string; name: string }[];
  discipline?: string;
  house_address?: string;
  house_address2?: string;
  city?: string;
  state?: string;
  country?: string;
  zip_code?: string;
  profile_picture?: string;
}

export const StaffData = () => {
  /** COMPONENT MOUNT */
  const [staffData, setStaffData] = useState<StaffDataType[]>([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [paginationParams, setPaginationParams] = useState<IPaginationParams>({
    page: 1,
    totalPages: 1,
    totalItems: 1,
    from: 1,
    to: 1,
  });
  const [totalPages, setTotalPage] = useState(1);
  const [isArchive, setIsAchive] = useState(false)
  const [editForm, setEditForm] = useState({
    id: "",
    surname: "",
    firstname: "",
    othername: "",
    gender: "",
    date_of_birth: "",
    email: "",
    phone_number: "",
    classroom_id: "",
    house_address: "",
    house_address2: "",
    city: "",
    state: "",
    country: "",
    zip_code: "",
    profile_picture: "",
    new_profile_picture: "",
    school_id: "",
    degree: "",
    discipline: "",
    class_type: "Secondary",
    institution: "DEFAULT",
    emergency_contact_name: "",
    emergency_contact_phone: "",
    emergency_contact_relationship: "",
  });
  const [imageUploaded, setImageUploaded] = useState(null) //Image user uploaded
  const [initialImage, setInitialImage] = useState(null) //Image to display
  const [editMode, setEditMode] = useState(false);



  /** FILTERINGS */
  const [searchTerm, setSearchTerm] = useState("");
  const [roleFilter] = useState("");
  const [filteredData, setFilteredData] = useState(staffData);
  const [changeClassMode] = useState(false);


  /** DATA FETCHING */
  async function fetchStaffData() {
    toast.remove();
    toast.loading("Fetching staff data...");
    setIsLoading(true);
    try {
      const response = await makeFetchRequest(`${defaultUrl}/teachers?page=${page}?surname=${
          searchTerm ? searchTerm : ""
        }`, true, false)

      setPaginationParams({
        ...paginationParams,
        page: response?.data?.meta?.current_page,
        totalPages: response?.data?.meta?.last_page,
        from: response?.data?.meta?.from,
        to: response?.data?.meta?.to,
        totalItems: response?.data?.meta?.total,
      });

      const data = response.data?.data.map((staff: any) => {
        return {
          ...staff,
        };
      });
      // SORT staff BY FIRST NAME
      const sortByName = data &&  data.sort(function (a: any, b: any) {
        return a?.firstname.localeCompare(b.firstname);
      });

      toast.remove();
      toast.success("Fetched Successfully");
      setStaffData(sortByName);
      setTotalPage(data?.data?.meta?.last_page);
      setIsLoading(false);
    } catch (err: any) {
      toast.remove();
      toast.error("Error Fetching Staff Data");
    }
  }

  const [classrooms, setClassrooms] = useState([]);
  const fetchClassrooms = async () => {
    const response = await makeFetchRequest(`${defaultUrl}/classroom`, false, true)
    setClassrooms(response || []);
    return response
  };

  function filterData() {
    const filtered = staffData
      .filter((data) => {
        if (!searchTerm.length) {
          return data;
        }
        const fullName = `${data.firstname ?? ""} ${data.othername ?? ""}  ${
          data.surname ?? ""
        } ${data.lastname ?? ""}`;
        return fullName.toLowerCase().includes(searchTerm.toLowerCase());
      })
      .filter((data) => {
        if (!roleFilter.length || !data.role) {
          // console.log(roleFilter.length, {role: data})
          return data;
        }
        return data.role?.toLowerCase() === roleFilter.toLowerCase();
      });

    // console.log({ filtered, staffData });
    setFilteredData(filtered);
    return filtered;
  }

  useEffect(() => {
    const searchBar = document.getElementById("searchStaffInput");
    searchBar?.focus();
    fetchStaffData();
    fetchClassrooms();
  }, [isArchive]);

  useEffect(() => {
    filterData();
  }, [searchTerm, roleFilter, staffData]);


  async function addStaffToClass({ classroom, teacher_id }: any) {
    const confirmAction = await Swal.fire({
      title: `Confirm Action`,
      text: `Are you sure you want to add Staff to this Class?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "navyblue",
      confirmButtonText: "Confirm",
    });
    if (confirmAction.isConfirmed) {
      const body = {
        name: classroom.name,
        teachers: [...classroom.teachers, teacher_id],
      }
      await makePostRequest(`${defaultUrl}/classroom/${classroom?.id}`, body)
      setIsAchive(!isArchive)
    }
  }

  function handleClickEdit(data: any) {
    // console.log({ data });
    setEditForm(data);
    setEditMode(true);
  }

  function handleInputChange({ target: { name, value, files } }: any) {
    setEditForm({
      ...editForm
    });
    return;
  }

    // IMAGE ONCHAGE
    const handleImageUpload = (e: any) => {
      const file = e.target.files[0]
      if (file) {
        setImageUploaded(file)
        const reader : any = new FileReader();
        reader.onloadend = () => {
          setInitialImage(reader.result);
        };
        reader.readAsDataURL(file);
      }

    }



  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsSaving(true);
    const formData = editForm;

    // if media is available
    let profile_pictureString;
    if (imageUploaded) {
      profile_pictureString = await uploadImageToCloudinary(imageUploaded)
      formData.profile_picture = profile_pictureString || ""
    }


    const body = {
      surname: editForm.surname?.trim(),
      firstname: editForm.firstname.trim(),
      othername: editForm.othername?.trim(),
      gender: editForm.gender?.trim(),
      date_of_birth: editForm.date_of_birth?.trim(),
      email: editForm.email?.trim(),
      phone_number: editForm.phone_number?.trim(),
      // classroom_id: editForm.classroom_id?.trim(),
      house_address: editForm.house_address?.trim(),
      house_address2: editForm.house_address2?.trim(),
      city: editForm.city?.trim(),
      state: editForm.state?.trim(),
      country: editForm.country?.trim(),
      zip_code: editForm.zip_code?.trim(),
      profile_picture: formData.profile_picture?.trim(),
      school_id: editForm.school_id?.trim(),
      degree: editForm.degree?.trim(),
      discipline: editForm.discipline?.trim(),
      class_type: editForm.class_type?.trim(),
      institution: editForm.institution?.trim(),
      emergency_contact_name: editForm.emergency_contact_name?.trim(),
      emergency_contact_phone: editForm.emergency_contact_phone?.trim(),
      emergency_contact_relationship: editForm.emergency_contact_relationship?.trim(),
    }

    await makePutRequest( `${defaultUrl}/teachers/${editForm.id}`, body)

    setEditForm({
      id: "",
      surname: "",
      firstname: "",
      othername: "",
      gender: "",
      date_of_birth: "",
      email: "",
      phone_number: "",
      classroom_id: "",
      house_address: "",
      house_address2: "",
      city: "",
      state: "",
      country: "",
      // admission_date: "",
      zip_code: "",
      profile_picture: "",
      new_profile_picture: "",
      school_id: "",
      institution: "DEFAULT",
      degree: "",
      discipline: "",
      class_type: "",
      emergency_contact_name: "",
      emergency_contact_phone: "",
      emergency_contact_relationship: "",
    });
    setEditMode(false);
    fetchStaffData();
    setIsSaving(false);

 };

  //put archive endpoint here
  function handleArchieve(id: string) {
    Swal.fire({
      icon: "warning",
      title: "Archive",
      text: "Are you sure you want to archive this staff?",
      showCancelButton: true,
      confirmButtonColor: "green",
      confirmButtonText: "Yes, archive",
      cancelButtonColor: "grey",
      cancelButtonText: "No, Cancel",
    }).then((response) => {
      if (response.isConfirmed) {
        makePutRequest(`${defaultUrl}/archiveTeacher/${id}`)
        setIsAchive(!isArchive)
      }
    });
  }




// RENDERED COMPONENT
  return (
    <>
      <div className="w-full flex items-center">
        <BackButton />
        <TopAdminNav name="Jane Doe" title="Staff Data" />
      </div>

      <div className="flex">
        <div className="block w-full h-[87vh] overflow-auto register-scroll">
          <div className="flex">
            <div className="w-full">
              <RegisterCard
                title="Staff Management"
                details="View and Manage all School Staff"
              />
            </div>
          </div>
          <div className="staffData border border-gray-100 !pb-0 !pt-[0.05px] mt-7 ">
            <div className="flex flex-wrap justify-start mr-6 md:justify-between text-[#1f1f1f]/[0.7] md:mt-6 mb-3 w-[98%] ">

              <div className="w-4/12 min-w-max pl-2 mt-6 md:mt-0">
                <div className="flex">
                  <div className="flex items-center px-2 pl-4 rounded-2xl border-2 border-gray-200 w-10/12 min-w-[200px] ">
                    <FontAwesomeIcon icon={faSearch} />
                    <input
                      id="searchStaffInput"
                      onChange={(e) => setSearchTerm(e.target.value)}
                      placeholder="Search name"
                      className="rounded-2xl pl-4 p-2 w-full focus:outline-none "
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="text-[#012063CC] mt-1 p-4 px-2">
              <div className=" w-full">
                <div className="w-full text-center overflow-auto">
                  <div className="flex justify-between px-3 w-[98%] !min-w-[700px]
                   mb-2 py-4 bg-[#F4F7FE] rounded-xl font-semibold border">
                    <div className="w-2/12 text-[0.9em] font-bold flex justify-center">
                      Photo
                    </div>
                    <div className="w-2/12 text-[0.9em] font-bold flex justify-center">
                      Name
                    </div>
                    <div className="w-2/12 text-[0.9em] font-bold flex justify-center">
                      Class
                    </div>
                    <div className="w-2/12 text-[0.9em] font-bold flex justify-center">
                      Contact
                    </div>
                    <div className="w-2/12 text-[0.9em] font-bold flex justify-center">
                      Emergency
                      <br />
                       Contact
                    </div>
                    <div className="w-1/12 min-w-[70px] text-[0.8em]"></div>
                  </div>
                  <div className="h-[58vh] w-[98%] !min-w-[700px]  overflow-auto register-scroll">
                    {isLoading ? (
                      <div className="flex justify-center m-4">
                        <div>
                          <img src={loader} alt="loader" className="" />
                        </div>
                      </div>
                    ) : staffData.length > 0 ? (
                      filteredData.map((item: any, index: number) => {
                        const fullName = `${item.firstname ?? ""} ${
                          item.othername ?? ""
                        }  ${item.surname ?? ""} ${item.lastname ?? ""}`;
                        return (
                          <div
                            key={index}
                            className="border !min-w-[700px] text-center text-[#012063]/[0.7] text-gray-600 bg-white py-1 rounded-xl mt-[5px]"
                          >
                            <div className="flex justify-between items-center w-full border-blue-500 px-3">
                              <div className="w-2/12 text-[0.9em] font-bold flex justify-center">
                                <div className="h-20 w-20 overflow-auto rounded-full">
                                  <LazyLoadImage
                                    style={{ height: "100%", width: "100%" }}
                                    src={item?.profile_picture}
                                    effect="blur"
                                    wrapperProps={{
                                        style: {transitionDelay: "1s"},
                                    }}
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="w-2/12">
                                <p className="text-center text-[14px] text-[#012063CC] capitalize font-semibold">
                                  {fullName}
                                </p>
                              </div>
                              <div className="w-2/12">
                                <div className="text-center text-[0.9em] min-w-max capitalize">
                                  {item?.classrooms.length > 0
                                   ?
                                  <p>{item?.classrooms[0]?.name?.slice(0,11) + "..."}</p>
                                  :
                                  ""
                                  }
                                </div>
                              </div>
                              <div className="w-2/12 mx-2.5">
                                <p className="text-center text-[0.85em]">
                                  {item.phone_number ?? ""}
                                  <br />
                                  <b>{item.email?.slice(0,12) ?? ""}</b>
                                  <br />
                                  <b>{item.email?.slice(12) ?? ""}</b>
                                </p>
                              </div>
                              <div className="w-2/12 mx-2.5">
                                <p className="text-center text-[0.85em]">
                                  <b>{item.emergency_contact_phone ?? ""}</b>
                                  <br />
                                  {item.emergency_contact_relationship ?? ""}
                                  <br />
                                  <b>{item.emergency_contact_name?.slice(0,12) ?? ""}
                                  <br />
                                  {item.emergency_contact_name?.slice(12, 24) ?? ""}</b>
                                </p>
                              </div>
                              <div className="relative w-1/12  ml-1 overflow
                               text-[14px] items-center flex justify-center text-[#fff]">
                                <Menu
                                  menuButton={
                                    <MenuButton
                                      className={`rounded px-4 py-1 bg-[#0096D1] text-gray-100`}
                                    >
                                      Options
                                    </MenuButton>
                                  }
                                >
                                  <div className="absolute top-0 -left-[84px] z-50 border-2
                                  w-44 bg-[#0096D1] text-[#0096D1] cursor-pointer">
                                    <MenuItem
                                      onClick={() => handleClickEdit(item)}
                                      className={`bg-white border-2 m-1 text-[14px] font-normal hover:text-amber-500`}
                                    >
                                      Edit profile
                                    </MenuItem>
                                    <MenuItem
                                      className={`bg-white border-2 m-1 text-[14px] font-normal hover:text-amber-500`}
                                      onClick={() => handleArchieve(item.id)}
                                    >
                                      Archive
                                    </MenuItem>
                                    <SubMenu
                                      label="Change Classroom"
                                      className={`bg-white border-2 m-1 text-[14px] font-normal hover:text-amber-500`}
                                    >
                                      {classrooms?.map((clas: any, idx : number) => {
                                        return (
                                          <MenuItem
                                          key={idx}
                                            onClick={() =>
                                              addStaffToClass({
                                                classroom: clas,
                                                teacher_id: item.id,
                                              })
                                            }
                                            className={`bg-[navy] text-white border-2 m-1 px-4 text-[14px] font-normal hover:text-amber-500`}
                                          >
                                            {item.classrooms
                                              ?.map((obj : any) => obj.id)
                                              .includes(clas.id) && (
                                              <FontAwesomeIcon
                                                icon={faCheckCircle}
                                                className="mr-1"
                                              />
                                            )}
                                            {clas.name}
                                          </MenuItem>
                                        );
                                      })}
                                    </SubMenu>
                                  </div>
                                </Menu>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="flex justify-center my-4 text-lg ">
                        No data found...
                      </div>
                    )}
                    <div>
                      <Pagination
                        page={page}
                        setPage={setPage}
                        totalPages={totalPages}
                        paginationParams={paginationParams}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {editMode && (
        <div className="">
          <CustomModal
            title={`Edit Staff - ${editForm.surname}`}
            width={75}
            children={
              <EditStaffForm
                initialImage={initialImage}
                form={editForm}
                handleChange={(e: any) => handleInputChange(e)}
                handleImageUpload={handleImageUpload}
                handleSubmit={handleSubmit}
                isLoading={isSaving}
              />
            }
            hideModal={() => setEditMode((prev) => !prev)}
          />
        </div>
      )}
      {changeClassMode && (
        <div className="">
          <CustomModal
            title={`Edit Staff - ${editForm.surname}`}
            width={75}
            children={
              <EditStaffForm
                form={editForm}
                handleChange={(e: any) => handleInputChange(e)}
                handleSubmit={handleSubmit}
              />
            }
            hideModal={() => setEditMode((prev) => !prev)}
          />
        </div>
      )}
    </>
  );
};
