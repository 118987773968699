import React from "react";
import "../assets/css/landing.css";
import { FaQuoteRight, FaQuoteLeft } from "react-icons/fa";
import { AiOutlinePhone, AiOutlineMail } from "react-icons/ai";
import { CiLocationOn } from "react-icons/ci";

export const Landingpage = () => {
  return (
    <div className="landing">
      <nav className="landingNav">
        <a href="/landingpage">
          <img src="/1.png" alt="" />
        </a>

        <ul className="navLists">
          <li>Pricing</li>
          <li>About</li>
          <li>Our App</li>
          <li>Newsletter</li>
          <li>Demo</li>
          <button>Sign In</button>
        </ul>
      </nav>
      <header className="landingheader">
        <div className="headerContainer">
          <div className="landingHeaderText">
            <h2>
              {" "}
              Digitalizing school operations, including learning and
              administration.
            </h2>
            <p>
              The adoption of digital tools and platforms to transform learning
              and administrative processes in schools
            </p>

            <div className="landingHeaderBtn">
              <button className="headerBtn1">Book a demo</button>
              <button className="headerBtn2">Learn how it works</button>
            </div>
            <div className="extraText">
              <span>“</span>
              <p>
                Monitoring and evaluation are essential for ensuring that
                education policies and programs are effective, efficient, and
                equitable.
              </p>
              <span>“</span>
            </div>
          </div>

          <div className="landingHeaderPic">
            <img src="2.png" alt="" />
            <div className="extraText">
              <span>“</span>
              <p>Measure progress, achieve success.</p>
              <span>“</span>
            </div>
          </div>
        </div>
        <div className="landingHeaderBtn2">
          <button className="headerBtn1">Book a demo</button>
          <button className="headerBtn2">Learn how it works</button>
        </div>
      </header>

      <section className="landingAbout">
        <img src="/11.svg" alt="" className="landingAboutimg" />
        <img src="/11m.svg" alt="" className="landingAboutimg2" />

        <p>
          <span>Pendulum</span> provides a user-friendly interface that enables
          you to effortlessly manage all activities related to parents,
          teachers, and students.
        </p>
      </section>
      <section className="landingHow">
        <h2>How Pendulum works</h2>
        <div className="howContent">
          <div className="howContentCap">
            <div className="howDoll">
              <img src="/4.png" alt="" />
            </div>
            <div className="howText">
              <img src="/5.png" alt="" />
              <p>
                Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et.
                Sunt qui esse pariatur duis deserunt mollit dolore cillum minim
                tempor enim. Elit aute irure tempor cupidatat incididunt sint
                deserunt ut voluptate aute id deserunt nisi.
              </p>
            </div>
          </div>

          <div className="howContentCap2">
            <div className="howText">
              <img src="/21.svg" alt="" />
              <p>
                Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et.
                Sunt qui esse pariatur duis deserunt mollit dolore cillum minim
                tempor enim. Elit aute irure tempor cupidatat incididunt sint
                deserunt ut voluptate aute id deserunt nisi.
              </p>
            </div>
            <div className="howDoll">
              <img src="/4.png" alt="" />
            </div>
          </div>
          <div className="howContentCap2">
            <div className="howText">
              <img src="/22.svg" alt="" />
              <p>
                Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et.
                Sunt qui esse pariatur duis deserunt mollit dolore cillum minim
                tempor enim. Elit aute irure tempor cupidatat incididunt sint
                deserunt ut voluptate aute id deserunt nisi.
              </p>
            </div>
            <div className="howDoll">
              <img src="/6.png" alt="" />
            </div>
          </div>
          <div className="howContentCap2 teh">
            <div className="howText">
              <img src="/23.svg" alt="" />
              <p>
                Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et.
                Sunt qui esse pariatur duis deserunt mollit dolore cillum minim
                tempor enim. Elit aute irure tempor cupidatat incididunt sint
                deserunt ut voluptate aute id deserunt nisi.
              </p>
            </div>
            <div className="howDoll">
              <img src="/3.svg" alt="" />
            </div>
          </div>

          <div className="howContentCap">
            <div className="howText2">
              <img src="/8.png" alt="" />
              <p>
                Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et.
                Sunt qui esse pariatur duis deserunt mollit dolore cillum minim
                tempor enim. Elit aute irure tempor cupidatat incididunt sint
                deserunt ut voluptate aute id deserunt nisi.
              </p>
            </div>
            <div className="howDoll2">
              <img src="/6.png" alt="" />
            </div>
          </div>
          <div className="howContentCap">
            <div className="howText2">
              <img src="/2.svg" alt="" />
              <p>
                Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et.
                Sunt qui esse pariatur duis deserunt mollit.
              </p>
            </div>
            <div className="howDoll22">
              <img src="/3.svg" alt="" />
            </div>
            <div className="howText">
              <img src="/1.svg" alt="" />
              <p>
                Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et.
                Sunt qui esse pariatur duis deserunt mollit.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="landingWho">
        <h2>Who can use Pendulum?</h2>

        <div className="whoContent">
          <div className="whoContentCap">
            <div className="capContainer">
              <div className="capText">
                <h2>School Admininstrators</h2>
                <ul>
                  <li>
                    Digitalizing school operations, including learning and
                    administration.
                  </li>
                  <li>
                    Digitalizing school operations, including learning and
                    administration.
                  </li>
                  <li>
                    Digitalizing school operations, including learning and
                    administration.
                  </li>
                  <li>
                    Digitalizing school operations, including learning and
                    administration.
                  </li>
                </ul>
              </div>
              <img src="4.svg" alt="" />
            </div>

            <p>
              “Our platform facilitates the efficient and effective management
              of activities involving parents, teachers, and students with ease
              and simplicity.”
            </p>
            <button>Explore our Feature</button>
          </div>
          <div className="whoContentCap">
            <div className="capContainer">
              <img src="5.svg" alt="" />
              <div className="capText2">
                <h2>Teachers</h2>
                <ul>
                  <li>
                    Digitalizing school operations, including learning and
                    administration.
                  </li>
                  <li>
                    Digitalizing school operations, including learning and
                    administration.
                  </li>
                  <li>
                    Digitalizing school operations, including learning and
                    administration.
                  </li>
                  <li>
                    Digitalizing school operations, including learning and
                    administration.
                  </li>
                </ul>
              </div>
            </div>

            <p>
              “Our platform facilitates the efficient and effective management
              of activities involving parents, teachers, and students with ease
              and simplicity.”
            </p>
            <button>Explore our Feature</button>
          </div>
          <div className="whoContentCap">
            <div className="capContainer">
              <div className="capText">
                <h2>Parent and Children</h2>
                <ul>
                  <li>
                    Digitalizing school operations, including learning and
                    administration.
                  </li>
                  <li>
                    Digitalizing school operations, including learning and
                    administration.
                  </li>
                  <li>
                    Digitalizing school operations, including learning and
                    administration.
                  </li>
                  <li>
                    Digitalizing school operations, including learning and
                    administration.
                  </li>
                </ul>
              </div>
              <img src="6.svg" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="testimony">
        <FaQuoteLeft className="tesIcon" />
        <p>
          Our school has been using this platform for a while now, and it has
          really helped us improve our overall performance. We can now easily
          monitor student progress and identify areas for improvement.
        </p>
        <FaQuoteRight className="tesIcon" />
      </section>
      <section className="landingDemo">
        <div className="demoCon">
          <img src="7.svg" alt="" />
          <button>Book a Demo</button>
        </div>
        <img src="9.svg" alt="" className="bac" />
      </section>

      <section className="newsletter">
        <div className="newsText">
          <h3>Join our Newsletter</h3>
          <p>Be the first to know. Be the first to benefit.</p>
          <input type="text" placeholder="Enter Name" />
          <input type="email" placeholder="Enter Email Address" />
        </div>

        <button>Subscribe</button>

        <div className="secondImg">
          <img src="8.svg" alt="" />
        </div>
      </section>

      <section className="download">
        <div className="downloadOut">
          <div className="downloadIn">
            <h2>Download our App</h2>

            <div className="downloadCont">
              <img src="10.svg" alt="" />
              <div className="downloadContText">
                <h3>Our App Solves All School Management Issues</h3>
                <ul>
                  <li>Lorem ipsum dolor sit amet, consectetur.</li>
                  <li>Lorem ipsum dolor si.</li>
                  <li>Lorem ipsum.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="landingFooter">
        <div className="footToop">
          <div className="footToopFiest">
            <img src="15.svg" alt="" />
            <p>
              We ara a lorem ipsum dolor sit amet, consectetur adipiscing elit,
              sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat...{" "}
              <a href="">Read More</a>
            </p>
            <div className="leftSu">
              <AiOutlinePhone />
              312-333-5678
            </div>
            <div className="leftSu">
              <AiOutlineMail />
              312-333-5678
            </div>
            <div className="leftSu">
              <CiLocationOn />
              312-333-5678
            </div>
          </div>
          <div className="footToop2">
            <ul>
              <h2>About</h2>
              <li>About Us</li>
              <li>Blog</li>
              <li>Career</li>
              <li>Jobs</li>
              <li>In Press</li>
              <li>Gallery</li>
              <li>Affiliate</li>
            </ul>
            <ul>
              <h2>Support</h2>
              <li>Contact Us</li>
              <li>Online Chat</li>
              <li>Whatsapp </li>
              <li>Telegram</li>
              <li>Ticketing</li>
              <li>Call Center</li>
              <li>Help</li>
            </ul>
            <ul>
              <h2>FAQ</h2>
              <li>Account</li>
              <li>Manage Deliveries</li>
              <li>Orders</li>
              <li>Payments</li>
              <li>Returns</li>
              <li>Copyright</li>
              <li>Language</li>
            </ul>
          </div>
        </div>
        <div className="footerBottom">
          <ul>
            <li>About us</li>
            <li>Contact</li>
            <li>Privacy policy</li>
            <li>Sitemap</li>
            <li>Terms of Use</li>
          </ul>
          <p>© 2000-2021, All Rights Reserved</p>
        </div>
      </footer>
    </div>
  );
};
