import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/css/main.css";
import logo from "../assets/white_logo.png";
import { CircleLoader } from "../components/CircleLoader";
import { detailsInterface, loginThunk } from "../features/actions";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../app/store";
import { BsEye, BsEyeSlash } from "react-icons/bs";

export const Login = () => {
  const [loading, setLoading] = useState(false);
  const [maskPassword, setMaskPassword] = useState(true);
  const [rememberMe, setRememberMe] = useState(true);
 const [signUp, setSignUp] = useState({
    email: "",
    password: "",
  });
  let dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleChange = ({ target: { name, value } }: any) => {
    setSignUp({ ...signUp, [name]: value });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);

    window.sessionStorage.removeItem("token");
    let data: detailsInterface = {
      email: signUp.email,
      password: signUp.password,
    };
    dispatch(loginThunk(data))
      .then(({ payload }: any) => {
        setLoading(false);


        if (typeof payload === "string") {
          throw new Error(payload);
        }

        sessionStorage.setItem("user", JSON.stringify(payload.data));
        sessionStorage.setItem("token", payload.token);

        const user = payload.data;
        const token = payload.token
        return {user, token};
      })
      .then(({user, token}) => {
        if (user.role === "teacher") {
          navigate("/staff", { state: {token} });
        } else if (user.role === "super_admin" || user.role === "admin") {
          navigate("/admin", { state: {token} });
        } else {
          navigate("/staff", { state: {token} });
        }

        setSignUp({
          email: "",
          password: "",
        });
      })
      .catch((err: any) => {
        setLoading(false);
        // console.log(err);
        toast.error("Login failed, please try again.");
      });
  };

  useEffect(() => {
    if (window.sessionStorage.getItem("token")) {
      navigate("/admin");
    }
  }, []);

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 h-[100vh] Login_bg">
      <div className="hidden md:block Login_bg_div px-20 pt-24 pb-20">
        <h2 className="text-white font-bold text-[27px] Login_bg_div_h2 border-none">
          <img src={logo} alt="logo here" />
        </h2>
        <div className="h-[70vh] relative ">
          <div className="absolute bottom-0">
            <h4 className="Login_bg_div_div_h4 font-bold text-[22px] pb-3">
              Do you know?
            </h4>
            <p className="Login_bg_div_div_p text-[18px] w-[400px] font-normal">
              “Education is the most powerful weapon <br /> which you can use to
              change the world.” - Nelson Mandela
            </p>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <div className="p-1 md:p-20 m-auto">
        <div className="w-[100%] md:w-[400px] flex-justify-center">
          <h2 className="text-center font-bold tracking-widest text-[#012063] text-[22px]">
            LOGIN PAGE
          </h2>
          <></>
          <br />
          <form onSubmit={handleSubmit} className="">
            <label className="text-[18px] tracking-normal text-[#012063] font-medium">
              Email
            </label>
            <input
              type="text"
              className="block rounded-sm Login_Input p-3 w-full"
              name="email"
              value={signUp.email}
              onChange={handleChange}
              required
            />
            <br />

            <label className="text-[18px] tracking-normal text-[#012063] font-medium">
              Password
            </label>
            <div className="relative flex items-center">
              <input
                type={`${maskPassword ? "password" : "text"}`}
                className="block rounded-sm Login_Input p-3 w-full"
                name="password"
                value={signUp.password}
                onChange={handleChange}
                required
              />
              <div
                className="absolute right-4 cursor-pointer"
                onClick={() => setMaskPassword(!maskPassword)}
              >
                {maskPassword ? <BsEye /> : <BsEyeSlash />}
              </div>
            </div>
            <label className="flex py-2">
              <input
                type="checkbox"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              />
              <p className="mx-2">Keep me signed in</p>
            </label>
            <div>
              <button type="submit" className="studentRegBtn">
                {loading ? <CircleLoader /> : "Log In"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
