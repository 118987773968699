import { RegisterCard } from "../components/RegisterCard";
import { TopAdminNav } from "../layouts/TopAdminNav";
import "../assets/css/main.css";
import "../assets/css/student.css";
import { useEffect,  useState } from "react";
import BackButton from "../components/backButton";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import CustomModal from "../components/CustomModal/CustomModal";
import { Classroom } from "./Classes";
import { EditStudentForm } from "../layouts/editForms/student";
import { AddParentForm } from "../layouts/editForms/parent";
import toast from "react-hot-toast";
import { AddPickupForm } from "../layouts/editForms/emergencyContact";
import { formatTime, uploadImageToCloudinary } from "../utilities/importantFns";
import { defaultUrl } from "../features/actions";
import { IPaginationParams } from "../types/interfaces";
import Pagination from "../components/pagination";
import {
  selectRe_RenderedState,
  setSharedState,
} from "../features/sharedStateSlices/shareState";
import { makeDeleteRequest, makeFetchRequest, makePostRequest, makePutRequest } from "../utilities/common";
// Expected Data Type Information
import { StudentDataInterface } from "../types/interfaces";
import StudentHeader from "../components/studentData/StudentHeader";
import { StudentTable } from "../components/studentData/StudentTable";
import { StudentFilter } from "../components/studentData/StudentFilter";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';


export const StudentData = () => {
  const dispatch = useDispatch();
  const reloaded = useSelector((state) => selectRe_RenderedState(state));

  /** DATA FETCHING */
  const [studentData, setStudentData] = useState<StudentDataInterface[]>([]);
  const [classrooms, setClassrooms] = useState<Classroom[]>([]);
  // console.log(studentData)
  const [editModeType, setEditModeType] = useState<null | string>(null);
  const [editForm, setEditForm] = useState<StudentDataInterface>({ id: "" });
  const [emergencyTotal, setEmergencyTotal] = useState<object[]>([]);
  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);
  const [editParentForm, setEditParentForm] = useState([
    {
      name: "",
      email: "",
      phone: "",
      home_number: "",
      work_number: "",
      relationship: "",
    },
  ]);
  const [editPickupForm, setEditPickupForm] = useState([
    {
      student_id: "",
      contact_name: "",
      phone: "",
      relationship: "",
      photo: "",
    },
  ]);

  // pagination parameters
  const [isLoading, setIsLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  const [paginationParams, setPaginationParams] = useState<IPaginationParams>({
    page: 1,
    totalPages: 1,
    totalItems: 1,
    from: 1,
    to: 1,
  });

  /** FILTERINGS **/
  const [searchTerm, setSearchTerm] = useState("");
  const [classRoomFilter, setClassRoomFilter] = useState("");
  const [filterByBirthday, setFilterByBirthday] = useState(false);
  const [isArchive] = useState(false);

  const fetchClassrooms = async () => {
    const response = await makeFetchRequest(`${defaultUrl}/classroom`);
    setClassrooms(response);
    return response;
  };

  const fetchStudentData = async (classrooms?: any) => {
    try {
      setIsLoading(true);
      // Get all students per page, if there is a search terrm i.e searchTerm,
      // Get student that fits that criteria
      const data = await makeFetchRequest(`${defaultUrl}/students?q=${searchTerm}&page=${page}`,true, true)
      setPaginationParams({
        ...paginationParams,
        totalPages: data?.data?.meta?.last_page,
        from: data?.data?.meta?.from,
        to: data?.data?.meta?.to,
        totalItems: data?.data?.meta?.total,
      });

      setTotalPage(data?.data?.meta?.last_page);

      // Instantiate data.data.data to response
      const response = (await classrooms) && data?.data.data;

      // Map through the response if there classrooms and response data,
      // And find an object from the response array which has a student.classroom_id
      //  that matches each classroom id from classrooms array
      // If that object exists, create a new object with the following keys:
      //  {...student,classroom,profile_picture}
      const students =
        (await (classrooms && response)) &&
        response.map((student: any) => {
          const classroom =
            classrooms &&
            classrooms?.find((c: any) => {
              return c.id === student.classroom_id;
            });

          return {
            ...student,
            classroom,
            profile_picture: (
              <LazyLoadImage
                style={{ height: "100%", width: "100%" }}
                src={student.profile_picture}
                effect="blur"
                wrapperProps={{
                    style: {transitionDelay: "1s"},
                }}
                alt="pupil"
              />
            ),
          };
        });

              // SORT students BY FIRST NAME
      const sortByName = students &&  students.sort(function (a: any, b: any) {
        return a?.firstname.localeCompare(b.firstname);
      });

      // From the newly created students data, check if each student
      // has a classroom name that matches the classRoomFilter content
      // Also check if their birthday is today.
      const filtered =
        (await sortByName) &&
        sortByName

          .filter((data: any) => {
            if (classRoomFilter.length === 0) {
              return data;
            } else {
              return (
                data?.classroom?.name.toLowerCase() ===
                classRoomFilter.toLowerCase()
              );
            }
          })
          .filter((data: any) => {
            if (!filterByBirthday) {
              return data;
            } else {
              let fmtToday = formatTime().split(" ")[0];
              return data.date_of_birth === fmtToday;
            }
          });
      setStudentData(filtered);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };


  useEffect(() => {
    fetchClassrooms().then((classes) => fetchStudentData(classes));
     // eslint-disable-next-line
  }, [reloaded]);


  useEffect(() => {
    fetchStudentData(classrooms);

    // eslint-disable-next-line
  }, [reloaded, searchTerm, classRoomFilter, filterByBirthday, page])

  /** ACTIONS */


  // Edit Actions
  function handleInputChange({ target: { name, value, files } }: any) {
    // console.log({ name, value, files });
    setEditForm((prev) => ({
      ...prev,
      [name]: files?.length > 0 ? files[0] : value,
    }));
    return;
  }


  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsSaveLoading(true);
    const formData = editForm;
    // console.log("form data", formData.profile_picture);

    // if media is available
    let profile_pictureString;
    if (
      typeof formData.profile_picture !== "string" &&
      formData.profile_picture.props === undefined
    ) {
      profile_pictureString = await uploadImageToCloudinary(formData.profile_picture);
      formData.profile_picture = profile_pictureString || ""

    } else {
      profile_pictureString = await uploadImageToCloudinary(formData.profile_picture.props.src);
      formData.profile_picture = profile_pictureString || ""
    }

    toast.remove();
    toast.loading("Saving profile...");
    const body = {
      ...formData,
      parent_id: editForm.parents?.map((p: any) => p.id),
    };
    await makePutRequest(`${defaultUrl}/students/${editForm.id}`, body);

    toast.remove();
    fetchStudentData();
    setIsSaveLoading(false);
    setEditForm({ id: "" });
    setEditModeType(null);

    dispatch(setSharedState());
  };


  async function handleParentSubmit(e: any, existingParents: any) {
    e.preventDefault();
    toast.remove();
    let response: any
    const childParents = editForm.parents;

    // Check if the child has parent/Guardian
    if (childParents) {
      // Check if the child has more than one parent/Guardian
      if (childParents.length > 1) {
        return Swal.fire({
          title: "Ooops!",
          text: "Maximum number (2) of Guardians/Parents reached, cannot add new Guardian/Parent",
          icon: "error",
          confirmButtonText: "Close",
          allowOutsideClick: true,
        });
      } else {
        dispatch(setSharedState());
        // Create new Parent
        toast.loading("Saving profile, please wait...");

        // IF PARENT DOES NOT EXIST
        if(!existingParents){

          let parentModified: Array<object> = [];

          // Add phone_number field to editParentForm
          editParentForm.map((item: any) => {
            const newParentObject = {
              name: item?.name,
              email: item?.email,
              phone: item?.phone,
              home_number: item?.home_number,
              work_number: item?.work_number,
              relationship: item?.relationship,
            };

            return parentModified.push(newParentObject);

          });


        // CREATE A PARENT
         response = await makePostRequest(`${defaultUrl}/parents/create`, { data: parentModified })
         .then(async (res) => {
          const result = res?.parent_id[0]
          linkParentToChild(result)
         })
         .catch((err: any) => {
          toast.remove()
          Swal.fire({
              title: `Error`,
              text: err?.response?.data?.message || "Network Error.",
              icon: "error",
              showCancelButton: false,
          })
         })

        }else{

          response = await existingParents
          linkParentToChild(response?.id)
        }

      }
    }
  }

  async function linkParentToChild(result:any) {

    if (editForm.parents) {
      const parent_id = [
        ...editForm.parents?.map((x: any) => x.id),
        result
      ];

      toast.remove();
      toast.loading("Attaching Parent to Child...");


      if(result){
        await makePutRequest( `${defaultUrl}/students/${editForm.id}`, {parent_id,})
        setEditParentForm([
          {
            name: "",
            email: "",
            phone: "",
            home_number: "",
            work_number: "",
            relationship: "",
          },
        ]);
        setEditModeType(null);
      }

    }
  }

  async function handlePickupSubmit() {
    // Implement submit functionality
    // e.preventDefault();
    toast.remove();
    toast.loading("Creating Emergency contact, please wait...");
    // Check if emergency contact is equal to 2.
    if (emergencyTotal.length > 1) {
      toast.remove();
      return Swal.fire({
        title: "Ooops!",
        text: "Maximum number (2) of Emergency contacts reached, cannot add new contacts",
        icon: "error",
        confirmButtonText: "Close",
        allowOutsideClick: true,
      });
    } else {
      // start sending data
        editPickupForm.forEach(async (picker, i) => {
          let formData = { ...picker };

          let photo = await uploadImageToCloudinary(picker.photo);

          formData.photo = photo || "";
          formData.student_id = editForm.id;

          await makePostRequest(`${defaultUrl}/emergency/create`, formData)
          setEditPickupForm([
            {
              student_id: "",
              contact_name: "",
              phone: "",
              relationship: "",
              photo: "",
            },
          ])
          setEditModeType(null)
          dispatch(setSharedState());

        });
    }
  }

  const handleParentInputChange = (
    index: number,
    { target: { name, value } }: any
  ) => {
    setEditParentForm((parent: any) => {
      parent[index][name] = value;
      return [...parent];
    });
  };

  const handlePickupInputChange = (
    index: number,
    { target: { name, value, files } }: any
  ) => {
    setEditPickupForm((person: any) => {
      if (files) {
        person[index][name] = files[0];
      } else {
        person[index][name] = value;
      }
      return [...person];
    });
  };

  async function getEmergencyContact(wardID: string) {
    toast.loading("Checking for existing emergency contacts...");
    toast.remove();
    const response = await makeFetchRequest(
      `${defaultUrl}/emergency/student/${wardID}`
    );
    setEmergencyTotal(response);
    setEditForm((pre) => ({ ...pre, emergencyContacts: response }));
    toast.remove();
    toast.success("Done!");
    return response?.length;
  }

  async function deleteEmergencyContact(id: string) {
    Swal.fire({
      title: `Confirm Action`,
      text: `Are you sure you want to delete this contact?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "red",
      confirmButtonText: "Delete",
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        makeDeleteRequest(`${defaultUrl}/emergency/delete/${id}`)
        getEmergencyContact(editForm.id);
        dispatch(setSharedState());
      }
    });
  }

  function handleArchieve(id: string) {
    Swal.fire({
      icon: "warning",
      title: "Archive Activity",
      text: "Are you sure you want to archive this pupil?",
      showCancelButton: true,
      confirmButtonColor: "green",
      confirmButtonText: "Yes, Archive",
      cancelButtonColor: "grey",
      cancelButtonText: "No, Cancel",
    }).then(async (response) => {
      if (response.isConfirmed) {
        await makePutRequest(`${defaultUrl}/archiveStudent/${id}`);
        dispatch(setSharedState());
      }
    });
  }



  return (
    <>
      <div className="w-full flex items-center">
        <BackButton />
        <TopAdminNav name="Jane Doe" title="Pupil Data" />
      </div>

      <div className="flex">
        <div className="block w-full">
          <div className="flex">
            <div className="w-full">
              <RegisterCard
                title="Pupil Management"
                details="Update on pupil"
              />
            </div>
          </div>
          <div className=" border border-gray-100 mt-7 attendance_bg ">

                <StudentFilter
                classrooms={classrooms}
                setClassRoomFilter={setClassRoomFilter}
                classRoomFilter={classRoomFilter}
                setSearchTerm={setSearchTerm}
                filterByBirthday={filterByBirthday}
                setFilterByBirthday={setFilterByBirthday}
                />

                <div className="text-[#012063CC] mt-1 p-4 px-2">
                <div className="w-full text-center overflow-x-auto">
                 <StudentHeader />
                  <div className="h-[60vh] w-[98%] !min-w-[700px] overflow-y-auto register-scroll">
                <StudentTable
                 classrooms={classrooms}
                 studentData={studentData}
                 isLoading={isLoading}
                 setEditForm={setEditForm}
                 setEditModeType={setEditModeType}
                 getEmergencyContact={getEmergencyContact}
                 handleArchieve={handleArchieve}
                />
                <Pagination
                totalPages={totalPage}
                page={page}
                setPage={setPage}
                paginationParams={paginationParams}
              />
            </div>
          </div>
        </div>
          </div>
        </div>
      </div>


      {editModeType === "child" && (
        <div className="">
          <CustomModal
            title={`Edit ${editForm.firstname}'s Data`}
            width={65}
            children={
              <EditStudentForm
                form={editForm}
                classrooms={classrooms}
                handleChange={(e: any) => handleInputChange(e)}
                handleSubmit={handleSubmit}
                isLoading={isSaveLoading}
              />
            }
            hideModal={() => {
              setEditModeType(null);
            }}
          />
        </div>
      )}


      {editModeType === "parent" && (
        <div className="">
          <CustomModal
            title={`Add a New Parent/Guardian to ${editForm.firstname}'s Profile `}
            width={60}
            children={
              <AddParentForm
                numberOfParents={editForm?.parents?.length}
                form={editParentForm}
                addParent={setEditParentForm}
                handleParentChange={(index: number, e: any) =>
                  handleParentInputChange(index, e)
                }
                handleParentSubmit={handleParentSubmit}
              />
            }
            hideModal={() => setEditModeType(null)}
          />
        </div>
      )}

      {editModeType === "pickup" && (
        <div className="">
          <CustomModal
            title={`Emergency Contacts`}
            width={60}
            children={
              <AddPickupForm
                deleteEmergencyContact={deleteEmergencyContact}
                pickups={editForm.emergencyContacts}
                numberOfPickups={editForm.emergencyContacts?.length || 0}
                form={editPickupForm}
                addPickup={setEditPickupForm}
                handlePickupChange={(index: number, e: any) =>
                  handlePickupInputChange(index, e)
                }
                handlePickupSubmit={handlePickupSubmit}
              />
            }
            hideModal={() => setEditModeType(null)}
          />
        </div>
      )}
    </>
  );
};
