import { useMemo } from "react";
import { faCameraRetro } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import countryList from "react-select-country-list";
import { CircleLoader } from "../../components/CircleLoader";

const relationshipArray = [
  "Father",
  "Mother",
  "Sister",
  "Brother",
  "Spouse",
  "Partner",
  "Others",
];

export const EditStaffForm = ({
  form,
  initialImage,
  handleImageUpload,
  handleChange,
  handleSubmit,
  isLoading,
}: any) => {
  const options = useMemo(() => countryList().getData(), []);

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <div className=" py-2">
          <div className="studentFormContent">
            <h4>First name</h4>
            <div className="nameForm">
              <input
                type="text"
                placeholder="First name"
                name="firstname"
                value={form?.firstname}
                onChange={(e: any) => handleChange(e)}
                required
              />
            </div>
          </div>
          <div className="studentFormContent">
            <h4>Last name</h4>
            <div className="nameForm">
              <input
                type="text"
                placeholder="Surnname"
                name="surname"
                value={form.surname}
                onChange={(e: any) => handleChange(e)}
                required
              />
            </div>
          </div>
          <div className="studentFormContent">
            <h4>Other names</h4>
            <div className="nameForm">
              <input
                type="text"
                placeholder="Other names"
                name="othername"
                value={form.othername}
                onChange={(e: any) => handleChange(e)}
              />
            </div>
          </div>

          <div className="studentFormContent">
            <h4>Email</h4>
            <div className="nameForm">
              <input
                type="email"
                placeholder="Email"
                name="email"
                value={form.email}
                onChange={(e: any) => handleChange(e)}
                required
              />
            </div>
          </div>
          <div className="studentFormContent">
            <h4>Gender</h4>
            <div className="genderForm">
              <span>
                <input
                  type="radio"
                  name="gender"
                  value="male"
                  checked={form.gender === "male"}
                  onChange={(e: any) => handleChange(e)}
                  required
                  className="p-2"
                />{" "}
                Male
              </span>
              <span>
                <input
                  type="radio"
                  name="gender"
                  value="female"
                  checked={form.gender === "female"}
                  onChange={(e: any) => handleChange(e)}
                  required
                />{" "}
                Female
              </span>
            </div>
          </div>
          <div className="studentFormContent">
            <h4>Date of Birth</h4>
            <div className="nameForm">
              <input
                type="date"
                name="date_of_birth"
                value={form.date_of_birth}
                onChange={(e: any) => handleChange(e)}
                required
              />
            </div>
          </div>
          <div className="studentFormContent">
            <h4>Mobile Number</h4>
            <div className="nameForm">
              <input
                type="tel"
                placeholder="Phone Number"
                name="phone_number"
                value={form.phone_number}
                onChange={(e: any) => handleChange(e)}
                required
              />
            </div>
          </div>
          <div className="studentFormContent items-center">
            <h4>Qualification</h4>
            <div className="educationdiv">
              <select
                name="degree"
                value={form.degree}
                className="educationSecond"
                onChange={(e: any) => handleChange(e)}
                required
              >
                <option value="">Select qualification</option>
                <option>Graduate</option>
                <option>Masters</option>
                <option>Postgraduate Diploma</option>
                <option>PhD</option>
                <option>Other Qualifications</option>
              </select>
              <input
                type="text"
                placeholder="Discipline Name"
                className="text-[14px] border-2 rounded w-40 h-10 px-2 shadow"
                name="discipline"
                value={form.discipline}
                onChange={(e: any) => handleChange(e)}
                required
              />
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div className="">
          <div>
            <div className="studentFormContent">
              <h4>Name</h4>
              <div className="nameForm">
                <input
                  type="text"
                  placeholder="House address"
                  name="house_address"
                  value={form.house_address}
                  onChange={(e: any) => handleChange(e)}
                  required
                />
              </div>
            </div>
            <div className="studentFormContent">

              <div className="phoneOption">
                <input
                  type="text"
                  placeholder="city"
                  name="city"
                  value={form.city}
                  required
                  onChange={(e: any) => handleChange(e)}
                />
                <input
                  type="text"
                  placeholder="state"
                  name="state"
                  value={form.state}
                  onChange={(e: any) => handleChange(e)}
                  required
                />
              </div>
            </div>
            <div className="studentFormContent">
              <h4></h4>
              <div className="nameForm">
                <select
                  name="country"
                  value={form.country}
                  onChange={(e: any) => handleChange(e)}
                  required
                  id=""
                >
                  <option value="">Select Country</option>
                  {options?.map((country: any) => {
                    return (
                      <option key={country.label} value={country.label}>{country.label}</option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="studentFormContent">
              <h4>Zip/Postal</h4>

              <div className="phoneOption">
                <input
                  type="text"
                  placeholder="zipcode"
                  name="zip_code"
                  value={form.zip_code}
                  onChange={(e: any) => handleChange(e)}
                />
              </div>
            </div>
            <div className="studentFormContent">
              <h4>Address 2</h4>
              <div className="phoneOption">
                <textarea
                  placeholder="Address 2"
                  name="house_address2"
                  className="p-3 text-[14px] text-gray-500 border-2"
                  value={form.house_address2 || ""}
                  onChange={(e: any) => handleChange(e)}
                ></textarea>
              </div>
            </div>
            <div className="text-[#012063B2] ml-6 mb-3 font-semibold">
              <h4>Emergency Contact</h4>
            </div>
            <div className="studentFormContent">
              <h4>Name</h4>
              <div className="nameForm">
                <input
                  type="text"
                  placeholder="emergency-contact"
                  name="emergency_contact_name"
                  value={form.emergency_contact_name}
                  onChange={(e: any) => handleChange(e)}
                />
              </div>
            </div>
            <div className="studentFormContent">
              <h4>Number</h4>
              <div className="nameForm">
                <input
                  type="text"
                  placeholder="Phone number"
                  name="emergency_contact_phone"
                  value={form.emergency_contact_phone}
                  onChange={(e: any) => handleChange(e)}
                />
              </div>
            </div>
            <div className="studentFormContent items-center">
              <h4 className="">Relationship</h4>
              <div className="educationdiv">
                <select
                  name="emergency_contact_relationship"
                  value={form.emergency_contact_relationship}
                  className="educationSecond"
                  onChange={(e: any) => handleChange(e)}
                >
                  {relationshipArray.map((item, idx: number) => {
                    return <option key={idx}>{item}</option>;
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="imageInput">
          <input
            type="file"
            name="new_profile_picture"
            onChange={(e: any) => handleImageUpload(e)}
          />

          {form.profile_picture && form.profile_picture !== "" ? (
            <div className="flex flex-col">
              <img className="w-full h-full" src={initialImage || form.profile_picture} alt="preview" />
            </div>
          ) : (
            <div className="imageOutline">
              {/* <AiOutlinePlus className="imageIcon" /> */}
              <FontAwesomeIcon
                icon={faCameraRetro}
                className="text-gray-100 text-[50px] animate-"
              />
            </div>
          )}
        </div>
      </div>
      <div className="w-full flex justify-center">
        <button className="rounded w-[300px] max-w-full mx-2 px-4 py-2 bg-cyan-900 text-white font-semibold bg-opacity-80">
          {isLoading ? (
            <div className="flex items-center gap-3">
              <CircleLoader/>
              <p>Saving changes...</p>
            </div>
          ) : (
            "Save all changes"
          )}
        </button>
      </div>
    </form>
  );
};
