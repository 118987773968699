import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectStudent } from "../../../features/student/studentSlice";


interface RenderStudentListProps {
  data: any;
  data2: any;
  mobilescreen: boolean;
}

const RenderStudentList = ({ data , data2, mobilescreen } : RenderStudentListProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div>
      {data && data.map((item: any, index: number) => {
        const classRoomName = data2 && data2.find((classItem: any) => classItem.id === item.classroom_id);
        return (
          <div key={index}
            className={`border border-gray-200 flex w-full min-h-[80px]
            text-center text-gray-600 bg-white shadow-xl my-3
            rounded-[25px] mt-2 p-2 ${mobilescreen ? 'flex-wrap' : 'flex-wrap'}`}>

            <div className="w-3/12 md:w-2/12 flex justify-center min-h-[20px]">
              <div className="h-16 w-16 overflow-auto rounded-full">
                {item.profile_picture}
              </div>
            </div>

            <div className="md:w-4/12 w-9/12 text-[0.9em] font-bold flex
                          justify-center min-h-[20px]">
              <p className="mx-2 my-auto">
                {`${item?.firstname} \u00A0\ ${item?.surname} \u00A0\ ${item?.othername}`}
              </p>
            </div>
            <div className="w-2/12 text-[0.9em] font-bold hidden md:flex
                          justify-center min-h-[20px]">
              <p className="my-auto">{item.date_of_birth}</p>
            </div>
            <div className="hidden w-2/12 text-[0.9em] font-bold
                          md:flex justify-center min-h-[20px]">
              <p className="my-auto">{classRoomName?.name}</p>
            </div>

            <div className="lg:w-2/12 w-full flex justify-end">
              <div className="my-auto">
                <button
                  onClick={() => {
                    dispatch(selectStudent(item.id));
                    navigate(`/staff/child-activities/${item.id}`);
                  }}
                  className="rounded
                           px-4 py-1 bg-[#0096D1] text-gray-100 text-[13px]">
                  View Activities
                </button>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RenderStudentList;
