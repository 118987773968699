import { RegisterCard } from "../../components/RegisterCard";
import { TimeSelect } from "../../components/TimeSelect";
import { CircleLoader } from "../../components/CircleLoader";
import { parseDateString } from "../../utilities/importantFns";

export const CAliquid = ({
  formData,
  onFormChange,
  onFormSubmit,
  loading,
}: any) => {
  const parsedDate = formData?.time ? parseDateString(formData.time) : new Date();

  return (
    <div className="w-full">
      <div className="w-full">
        <RegisterCard
          title="Activities"
          details="Send notification to parent/ guardians"
        />
        <br />
        <form onSubmit={onFormSubmit}>
          <div className="flex  flew-wrap items-center justify-between h-[90vh] sm:h-[70vh] px-2.5 min-h-max w-full overflow-x-hidden overflow-y-auto">
            <div className="!p-0 w-full md:w-2/3">
              <div className="w-full flex flex-col items-start md:flex-row md:items-center mt-5 md:mt-6">
                <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-left md:text-right w-1/3">
                  Liquid
                </h4>
                <div className="w-2/3 md:pl-6 ">
                  <div className="w-max max-w-[88vw] flex flex-wrap items-center justify-evenly rounded border bg-[#f5f5f5] p-4 py-3">
                    <label className="flex items-center mr-4 text-[14px]">
                      <input
                        type="checkbox"
                        name="liquid"
                        checked={formData["liquid"] === "water"}
                        onChange={(e) => onFormChange("liquid", "water")}
                        className="form-input checkbox mr-2"
                      />
                      Water
                    </label>
                    <label className="flex items-center mr-4 text-[14px]">
                      <input
                        type="checkbox"
                        name="liquid"
                        checked={formData["liquid"] === "breast milk"}
                        onChange={(e) => onFormChange("liquid", "breast milk")}
                        className="form-input checkbox mr-2"
                      />
                      Breast Milk
                    </label>
                    <label className="flex items-center mr-4 text-[14px]">
                      <input
                        type="checkbox"
                        name="liquid"
                        checked={formData["liquid"] === "milk"}
                        onChange={(e) => onFormChange("liquid", "milk")}
                        className="form-input checkbox mr-2"
                      />
                      Milk
                    </label>
                    <label className="flex items-center mr-4 text-[14px]">
                      <input
                        type="checkbox"
                        name="liquid"
                        checked={formData["liquid"] === "formula"}
                        onChange={(e) => onFormChange("liquid", "formula")}
                        className="form-input checkbox mr-2"
                      />
                      Formula
                    </label>
                    <label className="flex items-center mr-4 text-[14px]">
                      <input
                        type="checkbox"
                        name="liquid"
                        checked={formData["liquid"] === "other"}
                        onChange={(e) => onFormChange("quantity", "other")}
                        className="form-input checkbox mr-2"
                      />
                      Other
                    </label>
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col items-start md:flex-row md:items-center mt-5 md:mt-6">
                <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-left md:text-right w-1/3">
                  Time
                </h4>
                <div className="w-2/3 md:pl-6 flex items-center">
                  <div className="w-80 min-w-max h-10 mr-1 pb-1 rounded-lg border">

                    <TimeSelect
                      value={parsedDate ?? "09:00"}
                      onChange={(val: string) =>{
                        const date = new Date();
                        const formattedDate = date.toISOString().split('T')[0];
                        onFormChange("time", `${formattedDate} ${val}`)
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col items-start md:flex-row md:items-center mt-5 md:mt-6">
                <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-left md:text-right w-1/3">
                  Quantity
                </h4>
                <div className="w-2/3 md:pl-6 ">
                  <div className="w-max flex items-center justify-evenly rounded border bg-[#f5f5f5] p-4 py-3">
                    <label className="flex items-center mr-4 text-[14px]">
                      <input
                        type="checkbox"
                        name="quantity"
                        checked={formData["quantity"] === "all"}
                        onChange={(e) => onFormChange("quantity", "all")}
                        className="form-input checkbox mr-2"
                      />
                      All
                    </label>
                    <label className="flex items-center mr-4 text-[14px]">
                      <input
                        type="checkbox"
                        name="quantity"
                        checked={formData["quantity"] === "some"}
                        onChange={(e) => onFormChange("quantity", "some")}
                        className="form-input checkbox mr-2"
                      />
                      Some
                    </label>
                    <label className="flex items-center mr-4 text-[14px]">
                      <input
                        type="checkbox"
                        name="quantity"
                        checked={formData["quantity"] === "none"}
                        onChange={(e) => onFormChange("quantity", "none")}
                        className="form-input checkbox mr-2"
                      />
                      None
                    </label>
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col items-start md:flex-row md:items-center mt-5 md:mt-6">
                <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-left md:text-right w-1/3">
                  Description
                </h4>
                <div className="w-full md:w-2/3 md:pl-6">
                  <textarea
                    name="description"
                    required
                    defaultValue={formData["description"]}
                    onChange={(e) =>
                      onFormChange("description", e.target.value)
                    }
                    className="w-full border-2 p-4 focus:outline focus:outline-[#012063]"
                  ></textarea>
                </div>
              </div>

              <div className="mt-10 flex h-12 w-full">
                <div className="hidden md:block w-1/3"></div>
                <div className="w-full md:w-2/3 md:pl-6">
                  <button
                    type="submit"
                    className="flex items-center justify-center text-white w-full md:w-[200px] h-full font-bold rounded bg-gradient-to-b from-[#0096D1] to-[#012063]"
                  >
                    {loading ? <CircleLoader /> : "Send"}
                  </button>
                </div>
              </div>
            </div>
            <div className="flex justify-center ml-6 w-[200px] md:w-1/3 reMobile">
              <img
                src="/img/water-bottle.svg"
                alt="water"
                className="w-40 h-40"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
