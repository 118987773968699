import { Link } from "react-router-dom";
import "../assets/css/main.css";
import "../assets/css/student.css";
import { AiOutlinePlus} from 'react-icons/ai'

interface addUserCard {
  title: string;
  content: string;
  link?: string;
  hidden?: boolean;
}

export const AddUserCard = ({ title, content, link, hidden }: addUserCard) => {
  return (
    <Link to={`${link}`} className="link w-[350px] mr-3 mb-3">
      <div className="stud !w-full">
        <div className="studCon ">
          <h2 className="">{title}</h2>
          <p className="!w-[150px]">{content}</p>
        </div>
        <div className="studentcircleOutline">
          <div className="Studentcircle">
            <div className="iconbody">
              <p>
                <AiOutlinePlus />
              </p>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};
