import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

function BroadcastForm({mobileScreen, messageToSend, onChange, onSubmit}: any) {
  return (
    <form onSubmit={onSubmit} className="chatInput !max-w-[97%] blockys">
    <div className="chatInputDiv w-full flex items-center mr-4">
      <input
        type="text"
        value={messageToSend}
        onChange={onChange}
        className="p-4 rounded-xl"
        placeholder="Write a new message"
      />
      {mobileScreen ?
        <button
          type="submit"
        >
          <FontAwesomeIcon
            icon={faPaperPlane}
            className="rounded p-2 px-3 mr-2 bg-blue-900 text-white" />
        </button>
        :
        <button
          type="submit"
          className="rounded p-2 px-4 mr-2 bg-blue-900 text-white"
        >
          Send
        </button>}
    </div>

  </form>
  )
}

export default BroadcastForm
