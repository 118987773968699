import { RegisterCard } from "../components/RegisterCard";
import { TopAdminNav } from "../layouts/TopAdminNav";
import { Link } from "react-router-dom";
import "../assets/css/main.css";
import { useEffect, useState } from "react";
import "../assets/css/message.css";
import iconMessage from "../assets/messageadd1.svg";
import useWindowDimensions from "../hooks/useWindowSize";
import { useAppDispatch, } from "../app/hooks";
import {
  defaultUrl,
  openConversationThunk,
} from "../features/actions";
import Pagination from "../components/pagination";
import { IPaginationParams } from "../types/interfaces";
import toast from "react-hot-toast"
import { agoToDateString } from "../utilities/importantFns";
import { makeFetchRequest } from "../utilities/common";
import { ConversationList, ConversationListMobile } from "../components/Messages/ConversationList";
import SearchBar from "../components/Reusables/SearchBar";

export const Message = () => {
  const { width: windowWidth } = useWindowDimensions();
  const isMobileScreen = windowWidth < 440;
  let dispatch = useAppDispatch();
  const [conversationList, setConversationList] = useState([]);

  let [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [paginationParams, setPaginationParams] = useState<IPaginationParams>({
    page: 1,
    totalPages: 1,
    totalItems: 1,
    from: 1,
    to: 1,
  });

  async function fetchConversation() {
    try {
      const data = await makeFetchRequest(`${defaultUrl}/conversations?page=${page}`, true, true)
      toast.remove();
      toast.success("Success");
      setPaginationParams({
        ...paginationParams,
        totalPages: data?.data?.meta?.last_page,
        from: data?.data?.meta?.from,
        to: data?.data?.meta?.to,
        totalItems: data?.data?.meta?.total,
      });
      setTotalPage(data?.data?.meta?.last_page);
      setConversationList(data.data.data);
      const sortByDate = data.data.data?.sort((a: any, b: any) => {
        const a_date = agoToDateString(a.last_message_at)
        const b_date = agoToDateString(b.last_message_at)
        return a_date.localeCompare(b_date)
      })

      let filterData = sortByDate.reverse().filter((item: any) => {


        if (searchTerm.length < 1) {
          return item;
        }
        return item.recipient.toLowerCase().includes(searchTerm.toLowerCase());
      });

      setFiltered(filterData);
    } catch (error) {
      toast.remove();
      toast.error("Failed to fetch, Please try again");
    }
  }

  let [filtered, setFiltered] = useState(conversationList);



  // console.log(filtered)
  useEffect(() => {
    fetchConversation();
    // eslint-disable-next-line
  }, [searchTerm, page]);



  let handleSelectConvo = (convo: any) => {
    window.localStorage.setItem("parentMessageId", convo.recipient_id);
    dispatch(openConversationThunk(convo));
  };


  return (
    <>
      <div className="w-full">
        <TopAdminNav name="Jane Doe" title="Message" />
      </div>

      <div className="">
        <div>
          <RegisterCard
            title="School-wide conversation threads"
            details="Stay updated"
          />
        </div>


        {isMobileScreen ? (
          // MOBILE SCREENS
          <div className="px-1 !pb-0 !pt-[0.05px] mt-7 ">
            <div className="flex flex-wrap justify-start mr-6 md:justify-between text-[#1f1f1f]/[0.7] md:mt-6 mb-3 w-[98%] ">
              <div className="w-full flex items-center justify-end">
                <Link to="messagepick">
                  <button className="linearGradientButton1 text-white text-sm w-max p-2 mr-1 flex items-center">
                    <img src={iconMessage} alt="" className="mx-1 mr-2" />
                  </button>
                </Link>
              </div>
              <div className="w-full min-w-max mt-1 md:mt-0">
                <SearchBar onChange={(e: any) => setSearchTerm(e.target.value)} />
              </div>
            </div>
            <div className="text-[#012063CC] attendance_bg mt-1">
              <div className=" w-full">
                <div className="w-full text-center">
                  <div className="h-[68vh] w-[100%]  overflow-auto register-scroll ">
                    {filtered && filtered?.map((item: any, index: number) => {
                      let initials = item.recipient
                        .split(" ")
                        .map((item: any) => item.split("")[0])
                        .join("");
                      return (
                        <div
                          key={item.conversation_id}
                          onClick={() => handleSelectConvo(item)}
                          className="border text-center text-[#012063]/[0.7] text-gray-600 bg-white py-1 rounded-xl mt-[5px]"
                        >
                          <ConversationListMobile
                            recipientInitials={initials}
                            item={item}
                          />
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          // SCREENS NOT MOBILE
          <div className="studentData !pb-0 !pt-[0.05px] mt-7 ">
            <div className="flex flex-wrap justify-start mr-6 md:justify-between text-[#1f1f1f]/[0.7] md:mt-6 mb-3 w-[98%] ">
              <div className="w-6/12 flex items-center justify-start">
                <Link to="messagepick">
                  <button className="linearGradientButton1 text-white text-sm p-2 px-4 m-1 flex items-center">
                    <img src={iconMessage} alt="" className="mx-1 mr-2" /> New
                    message
                  </button>
                </Link>

              </div>
              <div className="w-3/12 min-w-max mt-6 md:mt-0">
                <SearchBar onChange={(e: any) => setSearchTerm(e.target.value)} />
              </div>
            </div>
            <div className="text-[#012063CC] attendance_bg mt-1 p-4 px-2">
              <div className=" w-full">
                <div className="w-full text-center mediaUpp">
                  <div className="h-[58vh] w-[98%]  overflow-auto register-scroll mediaUpd">
                    {filtered && filtered.map((item: any, index: number) => {
                      let initials = item.recipient
                        .split(" ")
                        .map((item: any) => item.split("")[0])
                        .join("");
                      return (
                        <div
                          onClick={() => handleSelectConvo(item)}

                          key={item.conversation_id}
                          className="border text-center text-[#012063]/[0.7] text-gray-600 bg-white py-1 rounded-xl mt-[5px] mediaUpd"
                        >
                          <ConversationList
                            recipientInitials={initials}
                            item={item}
                          />

                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
              <Pagination
                page={page}
                totalPages={totalPage}
                setPage={setPage}
                paginationParams={paginationParams}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
