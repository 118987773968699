import { RegisterCard } from "../../components/RegisterCard";
import "../../assets/css/activities.css";
import { CircleLoader } from "../../components/CircleLoader";
import { useEffect, useState } from "react";



export const CAlogout = ({
  formData,
  onFormChange,
  onFormSubmit,
  loading,
}: any) => {
  const [formattedTime, setFormattedTime] = useState("");

  useEffect(() => {
    if (formData.time) {
      const formattedValue = formData.time.replace("T", " ");
      setFormattedTime(formattedValue);
    }
  }, [formData.time]);

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace("T", " ");
    setFormattedTime(value);
    onFormChange("time", value);
  };



  return (
    <div className="w-full">
      <div className="w-full">
        <RegisterCard
          title="Log out time"
          details="Send notification to parent/ guardians"
        />
        <br />
        <form onSubmit={onFormSubmit}>
          <div className="flex  flew-wrap items-center justify-between h-[50vh] w-full">
            <div className="!p-0 md:w-2/3">
              <div className="w-full flex flex-col items-start md:flex-row md:items-center mt-6">
                <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-left md:text-right w-1/3">
                  Time
                </h4>
                <div className="w-2/3 md:pl-6 flex items-center">
                  <div className="w-80 min-w-max h-10 mr-1 pb-1 rounded-lg border">
                    <input
                      type="datetime-local"
                      value={formattedTime}
                      onChange={handleTimeChange}
                      style={{ width: "97%", height: " 100%", outline: "none", marginLeft: "auto" }}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col items-start md:flex-row md:items-center mt-6">
                <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-left md:text-right w-1/3">
                  Description
                </h4>
                <div className="w-full md:w-2/3 md:pl-6">
                  <textarea
                    name="description"
                    defaultValue={formData["description"]}
                    onChange={(e) =>
                      onFormChange("description", e.target.value)
                    }
                    className="w-full border-2 p-4 focus:outline focus:outline-[#012063]"
                  ></textarea>
                </div>
              </div>

              <div className="mt-10 flex h-12 w-full">
                <div className="hidden md:block w-1/3"></div>
                <div className="w-full md:w-2/3 md:pl-6">
                  <button className="flex items-center justify-center text-white w-full md:w-[200px] h-full font-bold rounded bg-gradient-to-b from-[#0096D1] to-[#012063]">
                    {loading ? <CircleLoader /> : "Send"}
                  </button>
                </div>
              </div>
            </div>
            <div className="flex justify-center ml-6 w-[200px] md:w-1/3 reMobile">
              <img src="/img/log-in.svg" alt="login" className="w-40 h-40" />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
