import { TopAdminNav } from "../layouts/TopAdminNav";
import "../assets/css/activities.css";
import BackButton from "../components/backButton";
import { useState, useEffect } from "react";
import ActivityCard from "../components/ActivityCard";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { CAhomework } from "../pages/classActivities/CAhomework";
import { CAattitude } from "../pages/classActivities/CAattitude";
import { CAfood } from "../pages/classActivities/CAfood";
import { CAhealth } from "../pages/classActivities/CAhealth";
import { CAliquid } from "../pages/classActivities/CAliquid";
import { CAlogin } from "../pages/classActivities/CAlogin";
import { CAlogout } from "../pages/classActivities/CAlogout";
import { CAnotes } from "../pages/classActivities/CAnotes";
import { CAsleep } from "../pages/classActivities/CAsleep";
import { CAsport } from "../pages/classActivities/CAsport";
import { CAsupplies } from "../pages/classActivities/CAsupplies";
import { CAtemeperature } from "../pages/classActivities/CAtemperature";
import { CAtoilet } from "../pages/classActivities/CAtoilet";
import useWindowDimensions from "../hooks/useWindowSize";
import { defaultUrl } from "../features/actions";
import { makeFetchRequest, makePostRequest } from "../utilities/common";
import { uploadImageToCloudinary } from "../utilities/importantFns";
interface Student {
  id: string;
  fullName?: string;
  firstname?: string;
  othername: string;
  lastname?: string;
  surname: string;
  profile_picture: string;
  dob: string;
  age: string;
  classroom: {
    id: number;
    name: string;
  };
  classroom_id?: string;
  allergies: [];
}
interface Activity {
  type?: string;
  student_id?: string[];
  user_id?: string[];
  description?: string;
  time?: string;
  image?: string[];
  video?: string;
  doc?: string;
  meal?: string;
  quantity?: string;
  health?: string;
  observation?: string;
  liquid?: string;
  mood?: string;
  morale?: string;
  sport_type?: string;
  items_needed?: string;
  toilet_type?: string;
  condition?: string;
  temperature?: string;
}


const ClassActivities = () => {

  const { width: windowWidth } = useWindowDimensions();
  const isMobileScreen = windowWidth < 440;
  const [currentStage, setCurrentStage] = useState(1);


  /** STEP 1 & 2 - SELECT ACTIVITY & SELECT CHILDREN*/
  const [selectedActivity, setSelectedActivity] = useState("");
  const [selectedChildren, setSelectedChildren] = useState<string[]>([]);

  const [studentData, setStudentData] = useState<Student[]>([]);
  const [classrooms, setClassrooms] = useState([]);

  const fetchClassrooms = async () => {
    const response = await makeFetchRequest(`${defaultUrl}/classroom`, false, true)
    setClassrooms(response || []);
    return response
  };

  const fetchStudentData = async () => {
    const data = await makeFetchRequest(`${defaultUrl}/students/withoutPagination`, false, true)
    const rawList = data
    const fmtList = rawList && rawList.map((item: Student, index: number) => {
      return {
        ...item,
        profile_picture: (
          <img
            style={{ height: "100%", width: "100%" }}
            src={item.profile_picture}
            alt="student"
          />
        ),
        fullName: `${item.firstname ?? ""} ${item.othername ?? ""} ${
          item.lastname ?? ""
        } ${item.surname ?? ""}`,
      };
    });
    // console.log(fmtList);
    setStudentData(fmtList);
  };

  function selectActivity(activity: string) {
    setSelectedActivity(activity);
    setCurrentStage(2);
  }
  function selectChild(childID: string) {
    if (selectedChildren.includes(childID)) {
      setSelectedChildren((prev) => {
        const filtered = prev.filter((child) => child !== childID);
        return filtered;
      });
    } else {
      setSelectedChildren((prev) => [...prev, childID]);
    }
  }
  function handleSelectAll() {
    if ((filteredData && filteredData.length) === (selectedChildren && selectedChildren.length)) {
      return setSelectedChildren([]);
    }
    const childrenIDs = filteredData && filteredData.map((child) => {
      return child.id;
    });
    setSelectedChildren(childrenIDs);
  }

  /** STEP 3 - ACTIVITY FORM AND DATA UPLOAD*/
  const [formData, setFormData] = useState<Activity>({});

  function handleFormChange(name: string, value: string) {
    // console.log({[name]: value })
    setFormData((prev) => {
      return { ...prev, [name]: value };
    });
  }
  const [loading, setLoading] = useState(false);

  async function handleSubmitForm(e: any) {
    setLoading(true);
    e.preventDefault();

    formData.type = selectedActivity;
    formData.student_id = selectedChildren;

    try {
      // if media is available
      let imageString;
      let videoString;
      let docString;
      if (formData.image) {
        imageString = await uploadImageToCloudinary(formData.image[0] ? formData.image[0] : formData.image);
        formData.image = [imageString] || ""
      } else {
        formData.image = ["null"];
      }

      if (formData.video) {
        videoString = await uploadImageToCloudinary(formData.video);
        formData.video = videoString || ""
      } else {
        formData.video = "null";
      }


      if (formData.doc) {
        docString = await uploadImageToCloudinary(formData.doc);
        formData.doc = docString || ""
      }

      await makePostRequest( `${defaultUrl}/activity_feed/create`, formData)
      setFormData({});
      setLoading(false);
      setCurrentStage(1);
      // navigate("/staff/class/activities")
    } catch (error: any) {
      // console.log({ errror: error });
      setLoading(false);
        Swal.fire({
          title: `Error!`,
          text: `${error?.data?.message || error.response?.data?.message}`,
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "red",
          // cancelButtonColor: "red",
          confirmButtonText: "Try Again",
          // cancelButtonText: "Leave Page",
        })
    }
  }



  /** FILTERINGS */
  const [searchTerm, setSearchTerm] = useState("");
  const [classRoomFilter, setClassRoomFilter] = useState("");
  const [filteredData, setFilteredData] = useState(studentData);


  function filterData() {
    const filtered = studentData && studentData
      .filter((data) => {
        if (!searchTerm.length) {
          return data;
        }

        return data?.fullName?.toLowerCase().includes(searchTerm.toLowerCase());
      })
      .filter((data) => {
        if (!classRoomFilter.length) {
          return data;
        }

        return (
          data.classroom_id?.toLowerCase() === classRoomFilter.toLowerCase()
        );
      });

    // console.log({ filtered });
    return filtered;
  }




  useEffect(() => {
    const searchBar = document.getElementById("searchAttendanceInput");
    searchBar?.focus();
    fetchStudentData();
    fetchClassrooms();
  }, []);

    /** COMPONENT UPDATE */
  useEffect(() => {
    setFormData({});
    setFilteredData(filterData());

  }, [selectedActivity, studentData, searchTerm, classRoomFilter]);



  /** CONDITION STATEMENTS VARIABLES */
  const selectAllConditions =
   (studentData &&  studentData.length > 0) && ((studentData && studentData.length) === (selectedChildren &&selectedChildren.length));
  // console.log(formData);
  return (
    <div>
      <div className="w-full flex items-center">
        <BackButton />
        <TopAdminNav name="Jone Doe" title="Class management" />
      </div>


{/* CURRENT STAGE 1 */}
      {currentStage === 1 && (
        <>
          <div className="cursor-pointer w-full md:w-1/2 my-2 flex items-center justify-between text-[14px] mlo">
            <h4>
              <span className="font-semibold text-blue-900">
                Select Activity
              </span>
              {" > "}
            </h4>
          </div>
          <div className="activities mt-4 pt-2 border-t-2 h-[80vh] !overflow-y-scroll register-scroll">
            <div className="activitiesCap justify-center">
              <div onClick={() => selectActivity("login")}>
                <ActivityCard
                  name="Log in"
                  img="class_login"
                  link="/admin/class/activities/login"
                />
              </div>
              <div onClick={() => selectActivity("homework")}>
                <ActivityCard
                  name="Home Work"
                  img="class_stickynote"
                  link="/admin/class/activities/Activities"
                />
              </div>
              <div onClick={() => selectActivity("health")}>
                <ActivityCard
                  name="Health"
                  img="class_healthcare"
                  link="/admin/class/activities/Health"
                />
              </div>
              <div onClick={() => selectActivity("temperature")}>
                <ActivityCard
                  name="Temperature"
                  img="class_thermometer"
                  link="/admin/class/activities/Temperature"
                />
              </div>
              <div onClick={() => selectActivity("food")}>
                <ActivityCard
                  name="Food"
                  img="class_balanceddiet"
                  link="/admin/class/activities/Food"
                />
              </div>
              <div onClick={() => selectActivity("liquids")}>
                <ActivityCard
                  name="Liquid"
                  img="class_waterbottle"
                  link="/admin/class/activities/Liquid"
                />
              </div>
              <div onClick={() => selectActivity("mood")}>
                <ActivityCard
                  name="Mood"
                  img="class_attitude"
                  link="/admin/class/activities/Mood"
                />
              </div>
              <div onClick={() => selectActivity("sleep")}>
                <ActivityCard
                  name="Sleep"
                  img="class_sleeping"
                  link="/admin/class/activities/Sleep"
                />
              </div>
              <div onClick={() => selectActivity("toilet")}>
                <ActivityCard
                  name="Toilet"
                  img="class_publictoilet"
                  link="/admin/class/activities/Toilet"
                />
              </div>
              <div onClick={() => selectActivity("note")}>
                <ActivityCard
                  name="Notes"
                  img="class_notes"
                  link="/admin/class/activities/Notes"
                />
              </div>
              <div onClick={() => selectActivity("activity")}>
                <ActivityCard
                  name="Activities"
                  img="class_trophy"
                  link="/admin/class/activities/Sport"
                />
              </div>
              <div onClick={() => selectActivity("supplies")}>
                <ActivityCard
                  name="Supplies"
                  img="class_stationery"
                  link="/admin/class/activities/Supplies"
                />
              </div>
              {/* <div onClick={()=> selectActivity("createQuiz")}>
          <ActivityCard
            name="Create Quiz"
            img="class_quiz"
            link="/admin/class/quiz"
          />
          </div>
          <div onClick={()=> selectActivity("reportCard")}>
          <ActivityCard
            name="Report Card"
            img="class_report"
            link="/admin/class/activities/ReportCard"
          />
          </div> */}
              <div onClick={() => selectActivity("logout")}>
                <ActivityCard
                  name="Log Out"
                  img="class_logout"
                  link="/admin/class/activities/LogOut"
                />
              </div>
            </div>
          </div>
        </>
      )}

{/* CURRENT STAGE 1 */}
      {currentStage === 2 && (
        <>
          <div className="cursor-pointer w-full md:w-1/2 my-2 flex items-center justify-between text-[14px] mlo">
            <h4>
              <span
                className="underline font-semibold text-orange-500"
                onClick={() => setCurrentStage(1)}
              >
                Select Activity
              </span>
              {" > "}
              <span className="font-semibold text-blue-900">Select Pupils</span>
            </h4>
          </div>
          {isMobileScreen ? (
            <div className="mt-4 bg-gray-50">
              <div className="flex items-center justify-start max-h-16 w-[100%] md:w-[90%] lg:w-[70%] md:justify-between text-[#1f1f1f]/[0.7] mb-3">
                <label
                  className={`rounded-lg border flex items-center justify-between w-max min-w-max h-max py-2 px-1 mr-2 text-[15px] ${
                    selectAllConditions && "border-blue-800"
                  }`}
                >
                  <input
                    type="checkbox"
                    checked={selectAllConditions}
                    className=""
                    onChange={handleSelectAll}
                  />
                  <span className="ml-1">({selectedChildren && selectedChildren.length})</span>
                </label>
                <div className="w-full min-w-max md:mt-0">
                  <div className="flex items-center px-2 pl-4 rounded-2xl border-2 border-gray-200 w-full min-w-[200px] ">
                    <FontAwesomeIcon icon={faSearch} />
                    <input
                      id="searchAttendanceInput"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      placeholder="Search name"
                      className="rounded-2xl pl-4 p-2 w-full focus:outline-none "
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap items-start justify-center max-h-[60vh] !overflow-y-scroll register-scroll">
                {filteredData && filteredData.map((student, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => selectChild(student.id)}
                      className={`min-w-max m-1`}
                    >
                      <ActivityCard
                        name={student.fullName ?? ""}
                        img={student.profile_picture}
                        imgAsSrc
                        windowWidth={windowWidth}
                        selected={selectedChildren.includes(student.id)}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="w-full md:w-1/2 mt-4 flex items-center justify-between text-[14px]">
                <button
                  onClick={() => setCurrentStage(1)}
                  className="rounded rounded-l-3xl w-[90px] py-1 text-white bg-gray-800 bg-opacity-80"
                >
                  Previous
                </button>
                <button
                  disabled={selectedChildren && selectedChildren.length < 1}
                  onClick={() => setCurrentStage(3)}
                  className={`rounded rounded-r-3xl w-[90px] py-1 mr-1 text-white bg-opacity-90 ${
                    selectedChildren && selectedChildren.length < 1 ? "bg-gray-500" : "bg-blue-800"
                  }`}
                >
                  Next
                </button>
              </div>
            </div>
          ) : (
            <div className="activities mt-4 bg-gray-50">
              <div className="flex flex-wrap gap-3 justify-start md:justify-between text-[#1f1f1f]/[0.7] md:mt-4 mb-3 w-[98%] md:w-[90%] lg:w-[70%]">
                <label
                  className={`rounded-lg border flex items-center justify-between min-w-max h-max p-2 px-4 mr-2 text-[15px] ${
                    selectAllConditions && "border-blue-800"
                  }`}
                >
                  <input
                    type="checkbox"
                    checked={selectAllConditions}
                    className="mr-4"
                    onChange={handleSelectAll}
                  />
                  Select all
                  <span className="ml-2">({selectedChildren && selectedChildren.length})</span>
                </label>
                <div className="w-3/12 w-full max-w-[240px] min-w-max">
                  <select
                    onChange={(e: any) => {
                      const {value} = e.target

                      setClassRoomFilter(value)

                      // FILTER CLASSROOMS THAT HAS ID == value
                      const filteredClassroomByID = filteredData && filteredData.filter( (item, idx) => {
                        return item?.classroom_id  === value
                      })

                      // Set it as the displayed date for the page
                      setFilteredData(filteredClassroomByID)


                    }}
                    className="border !rounded-xl w-full max-w-[240px] min-w-[214px] mr-2 p-2 text-center focus:outline-none"
                    value={classRoomFilter}
                  >
                    <option value="">All Classrooms</option>
                    {classrooms && classrooms?.map((classroom: any) => (
                      <option value={classroom.id} key={classroom.id}>{classroom.name}</option>
                    ))}
                  </select>
                </div>
                <div className="w-4/12 min-w-max mt-6 md:mt-0">
                  <div className="flex items-center px-2 pl-4 rounded-2xl border-2 border-gray-200 w-10/12 min-w-[200px] ">
                    <FontAwesomeIcon icon={faSearch} />
                    <input
                      id="searchAttendanceInput"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      placeholder="Search name"
                      className="rounded-2xl pl-4 p-2 w-full focus:outline-none "
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="activitiesCap max-h-[60vh] !overflow-y-scroll register-scroll justify-center">
                {filteredData && filteredData.map((student, index) => {
                  return (
                    <div key={index} onClick={() => selectChild(student.id)}>
                      <ActivityCard
                        name={student.fullName ?? ""}
                        img={student.profile_picture}
                        imgAsSrc
                        selected={selectedChildren.includes(student.id)}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="w-full md:w-4/5 mt-4 flex items-center justify-end py-3">
                {/* <button
                  onClick={() => setCurrentStage(1)}
                  className="rounded rounded-l-3xl w-[90px] py-3 text-white bg-gray-800 bg-opacity-80"
                >
                  Previous
                </button> */}
                <button
                  disabled={selectedChildren && selectedChildren.length < 1}
                  onClick={() => setCurrentStage(3)}
                  className={`rounded px-2 py-1 text-white bg-opacity-90 ${
                    selectedChildren && selectedChildren.length < 1 ? "bg-gray-500" : "bg-blue-800"
                  }`}
                >
                  Proceed
                </button>
              </div>
            </div>
          )}
        </>
      )}

      {currentStage === 3 && (
        <div>
          <div className="cursor-pointer w-full md:w-1/2 my-2 flex items-center justify-between text-[14px] mlo">
            <h4>
              <span
                className="underline font-semibold text-orange-500"
                onClick={() => setCurrentStage(1)}
              >
                Select Activity
              </span>
              {" > "}
              <span
                className="underline font-semibold text-orange-500"
                onClick={() => setCurrentStage(2)}
              >
                Select Pupils...
              </span>
              {" > "}
              <span className="font-semibold text-blue-900">Enter details</span>
            </h4>
          </div>
          <div className="">
            {selectedActivity === "homework" && (
              <CAhomework
                formData={formData}
                loading={loading}
                onFormChange={handleFormChange}
                onFormSubmit={handleSubmitForm}
              />
            )}
            {selectedActivity === "mood" && (
              <CAattitude
                loading={loading}
                formData={formData}
                onFormChange={handleFormChange}
                onFormSubmit={handleSubmitForm}
              />
            )}
            {selectedActivity === "food" && (
              <CAfood
                formData={formData}
                loading={loading}
                onFormChange={handleFormChange}
                onFormSubmit={handleSubmitForm}
              />
            )}
            {selectedActivity === "health" && (
              <CAhealth
                formData={formData}
                loading={loading}
                onFormChange={handleFormChange}
                onFormSubmit={handleSubmitForm}
              />
            )}
            {selectedActivity === "liquids" && (
              <CAliquid
                formData={formData}
                loading={loading}
                onFormChange={handleFormChange}
                onFormSubmit={handleSubmitForm}
              />
            )}
            {selectedActivity === "login" && (
              <CAlogin
                formData={formData}
                loading={loading}
                onFormChange={handleFormChange}
                onFormSubmit={handleSubmitForm}
              />
            )}
            {selectedActivity === "logout" && (
              <CAlogout
                formData={formData}
                loading={loading}
                onFormChange={handleFormChange}
                onFormSubmit={handleSubmitForm}
              />
            )}
            {selectedActivity === "note" && (
              <CAnotes
                formData={formData}
                loading={loading}
                onFormChange={handleFormChange}
                onFormSubmit={handleSubmitForm}
              />
            )}
            {selectedActivity === "sleep" && (
              <CAsleep
                formData={formData}
                loading={loading}
                onFormChange={handleFormChange}
                onFormSubmit={handleSubmitForm}
              />
            )}
            {selectedActivity === "activity" && (
              <CAsport
                formData={formData}
                loading={loading}
                onFormChange={handleFormChange}
                onFormSubmit={handleSubmitForm}
              />
            )}
            {selectedActivity === "supplies" && (
              <CAsupplies
                formData={formData}
                loading={loading}
                onFormChange={handleFormChange}
                onFormSubmit={handleSubmitForm}
              />
            )}
            {selectedActivity === "temperature" && (
              <CAtemeperature
                formData={formData}
                loading={loading}
                onFormChange={handleFormChange}
                onFormSubmit={handleSubmitForm}
              />
            )}
            {selectedActivity === "toilet" && (
              <CAtoilet
                formData={formData}
                loading={loading}
                onFormChange={handleFormChange}
                onFormSubmit={handleSubmitForm}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ClassActivities
