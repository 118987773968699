import { Link } from "react-router-dom";
import '../assets/css/activities.css'
import useWindowDimensions from "../hooks/useWindowSize";

interface ActivityCardInterface {
  img: string;
  name: string;
  link?: string;
  imgAsSrc?: boolean;
  selected?: boolean;
  windowWidth?: number;
}

export default function ActivityCard({ name, img, link, imgAsSrc, selected }: ActivityCardInterface) {
  const { width: windowWidth } = useWindowDimensions();
  const isMobileScreen = windowWidth < 840;
  return (
    <div className={`!p-[0.5px] cursor-pointer ${isMobileScreen? `rounded border shadow-lg ${imgAsSrc? 'w-40' : 'bg-gray-50'}` : `activity w-[150px]`}  ${selected && "!bg-blue-800 !bg-opacity-80"}`}>
      
      {
        isMobileScreen? (
          <div className={`!m-0 !mb-1`}>
            {imgAsSrc? 
              <div className=" h-24 pb-1 w-[100px] pt-1 mx-auto rounded-full overflow-hidden w-[60%]">
                {img}
              </div>
              : 
              <img
                src={ `../../img/${img}.png`}
                alt={`${name}`}
                className={`h-15 w-[80px] !m-0 ${imgAsSrc? '' : 'p-2'}`}
              />
            }
          </div>
        )
        :
        (
          <div className={`${!imgAsSrc && "activityImg"} !m-0 !mb-1`}>
            {imgAsSrc? 
              <div className="h-32 w-[150px] pt-1 mx-auto">
                {img}
              </div>
              : 
              <img
                src={ `../../img/${img}.png`}
                alt={`${name}`}
                width={120}
                className="w-full !m-0"
              />
            }
          </div>
        )
      }

      {imgAsSrc? 
      <p className={`w-36 mx-auto mt-0 h-10 border-box overflow-hidden text-xs text-center ${selected? "text-white" : "text-blue-800"}`}>{name}</p>
      :
      <h2 className={`mt-0 text-center ${isMobileScreen? 'text-[10px]' : 'text-md'}`}>{name}</h2>}
    </div>
  );
}
