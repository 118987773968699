import { MediaUploadInput } from "../../components/MediaUploadInput";
import { RegisterCard } from "../../components/RegisterCard";
import { AiTwotoneCamera, AiFillVideoCamera } from "react-icons/ai";
import { CircleLoader } from "../../components/CircleLoader";

export const CAsport = ({formData, onFormChange, onFormSubmit,loading}:any) => {
  return (
    <div className="w-full">
      <div className="w-full">
        <RegisterCard
          title="School Activities"
          details="Send notification to parent/ guardians"
        />
        <br />
        <form onSubmit={onFormSubmit}>
          <div className="flex  flew-wrap items-center justify-between h-[90vh] sm:h-[70vh] mt-10 md:mt-0 w-full">
            <div className="!p-0 md:w-2/3">
              <div className="w-full flex flex-col items-start md:flex-row md:items-center mt-6">
                <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-left md:text-right w-1/3">Type</h4>
                <div className="w-full md:w-2/3 md:pl-6 ">
                  <div className="w-max flex items-center justify-evenly rounded border bg-[#f5f5f5] p-4 py-3">
                    <select required defaultValue={""} className="border-none focus:outline-none bg-transparent" name="activity_type" onChange={(e)=> onFormChange("activity_type", e.target.value)}>
                      <option value="">Select</option>
                      <option value="learning">Learning</option>
                      <option value="playing">Playing</option>
                      {/* <option value="others">others</option> */}
                    </select>
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col items-start md:flex-row md:items-center mt-6">
                <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-left md:text-right w-1/3">Morale</h4>
                <div className="w-2/3 md:pl-6 ">
                  <div className="w-max max-w-[88vw] flex flex-wrap items-center justify-evenly rounded border bg-[#f5f5f5] p-4 py-3">
                    <label className="flex items-center mr-4 text-[14px]">
                      <input type="checkbox" name="morale" checked={formData["morale"] === "very active"} onChange={(e)=> onFormChange("morale", "very active")}  className="form-input checkbox mr-2" />
                      Very Active
                    </label>
                    <label className="flex items-center mr-4 text-[14px]">
                      <input type="checkbox" name="morale" checked={formData["morale"] === "normal"} onChange={(e)=> onFormChange("morale", "normal")}  className="form-input checkbox mr-2" />
                      Normal
                    </label>
                    <label className="flex items-center mr-4 text-[14px]">
                      <input type="checkbox" name="morale" checked={formData["morale"] === "not active"} onChange={(e)=> onFormChange("morale", "not active")}  className="form-input checkbox mr-2" />
                      Not Active
                    </label>
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col items-start md:flex-row md:items-center mt-6">
                <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-left md:text-right w-1/3">Description</h4>
                <div className="w-full md:w-2/3 md:pl-6">
                  <textarea name="description" defaultValue={formData["description"]} onChange={(e)=> onFormChange("description", e.target.value)} className="w-full border-2 p-4 focus:outline focus:outline-[#012063]"></textarea>
                </div>
              </div>
              <div className="w-full flex flex-col items-start md:flex-row md:items-center mt-6">
                <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-left md:text-right w-1/3">Photo or Video</h4>
                <MediaUploadInput singleOption={false} onChangeFn={(e:any, type:any)=> onFormChange(type==="video"?"video" : "image", e && type==="video"? e.target.files[0] : e && type==="image"? e.target.files : "")} className="w-2/3 md:pl-6 flex flex-col items-center" />
              </div>

              <div className="mt-10 flex h-12 w-full">
                <div className="hidden md-block w-1/3"></div>
                <div className="w-full md:w-2/3 md:pl-6">
                  <button className="flex items-center justify-center text-white w-full md:w-[200px] h-full font-bold rounded bg-gradient-to-b from-[#0096D1] to-[#012063]">
                     {loading ? <CircleLoader /> : "Send"}
                  </button>
                </div>
              </div>
            </div>
            <div className="flex justify-center ml-6 w-[200px] md:w-1/3 reMobile">
              <img
                src="/img/trophy.svg"
                alt="login"
                className="w-40 h-40"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
