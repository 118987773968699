import "../../assets/css/studentRegistration.css";
import { AiOutlinePlus } from "react-icons/ai";
import { CircleLoader } from "../CircleLoader";

export default function Third({
  handleChange,
  handleSubmit,
  loading,
  form,
  nextStep
}: any) {

  return (
    <>
      <div className="indicator">
        <div onClick={() => nextStep(1)} className="firstInd">1</div>
        <div onClick={() => nextStep(2)} className="firstInd">2</div>
        <div onClick={() => nextStep(3)} className="firstInd2">3</div>
      </div>
      <form onSubmit={(e) => { e.preventDefault(); nextStep(3); }} className="regStudentForm thirRed">

        <div>
          <div className="">
            <div className="studentFormContent">
              <h4>Condition</h4>
              <div className="medForm">
                <select
                  className="!text-[14px]"
                  name="condition"
                  value={form.condition[0]}
                  onChange={
                    (e) => {
                     form.condition[0] = e.target.value;
                     handleChange(e.target.value)
                    }
                    }

                  required
                >
                  <option value="" disabled>Allergy / Medical</option>
                  <option value="none" className="font-[600]">None</option>
                  <option value="water">Water</option>
                  <option value="Food">Food</option>
                  <option value="Water">Drug</option>
                  <option value="Soda">Soda</option>
                </select>
                {form.condition[0] !== "none" &&
                  <div className="genderForm">
                    <span>
                      <input
                        type="radio"
                        name="extent"
                        value="mild"
                        // checked={form.extent === "mild"}
                        onChange={
                          (e) => {
                            form.extent[0] = e.target.value;
                            handleChange(e.target.value)
                           }
                        }
                        required
                      />
                      Mild
                    </span>
                    <span>
                      <input
                        type="radio"
                        name="extent"
                        onChange={
                          (e) => {
                            form.extent[0] = e.target.value;
                            handleChange(e.target.value)
                           }
                        }
                        // checked={form.extent === "moderate"}
                        value="Moderate"
                        required
                      />
                      Moderate
                    </span>
                    <span>
                      <input
                        type="radio"
                        onChange={
                          (e) => {
                            form.extent[0] = e.target.value;
                            handleChange(e.target.value)
                           }
                        }
                        name="extent"
                        value="Severe"
                        // checked={form.extent === "severe"}
                        required
                      />
                      Severe
                    </span>
                  </div>}
              </div>
            </div>
          </div>
          <div className="imageOut">
            <h2>Profile Picture</h2>
            <label className="imageInput flex flex-col">
              <input
                type="file"
                name="profile_picture"
                className=""
                onChange={({ target }) => handleChange(target)}
                required={!form.profile_picture}
              />
              {!form.profile_picture && <div className="imageOutline">
                <AiOutlinePlus className="imageIcon" />
              </div>}
              {(form.profile_picture && form.profile_picture instanceof Blob)
                &&
                <div className="w-full h-full">
                  <img src={URL.createObjectURL(form.profile_picture)} alt="preview" />
                </div>}
            </label>
          </div>
        </div>
        <button
          type="submit"
          className="studentRegBtn"
        >
          {loading ? <CircleLoader /> : "Next step"}
        </button>
      </form>
    </>
  );
}
