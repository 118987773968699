import { RegisterCard } from "../../components/RegisterCard";
import { useState } from "react";
import { CircleLoader } from "../../components/CircleLoader";

export const CAsupplies = ({formData, onFormChange, onFormSubmit, loading}:any) => {

  let [items, setItems] = useState<any[]>([]);

  function handleSupplySelect(supply:string, target:any){
    if(target.checked){
      setItems((prev)=> [...prev, supply]);
    } else {
      setItems(prev=> prev.filter((item:any) => item !==supply));
    }
  }

  function handleSubmit(e:any){
    e.preventDefault();
    formData.items_needed = items;
    onFormSubmit(e);
  }

  return (
    <div className="w-full">
      <div className="w-full">
        <RegisterCard
          title="Supplies"
          details="Send notification to parent/ guardians"
        />
        <br />
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col md:flex-row items-center
          justify-between h-[90vh] sm:h-[70vh] w-full px-2.5">
            <div className="!p-0 md:w-2/3 w-full">
              <div className="w-full flex flex-col items-start md:flex-row md:items-center mt-6">
                <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-left md:text-right md:w-1/3 w-1/6">Type</h4>
                <div className="w-2/3 md:pl-6">
                  <div className="w-max flex items-center justify-evenly rounded border bg-[#f5f5f5] p-4 py-3">
                    <div>
                      <label className="flex items-center mr-4 text-[14px]">
                        <input type="checkbox" name="diapers" checked={items.includes("diapers")} onClick={(e)=> {handleSupplySelect("diapers", e.target)}}  className="form-input checkbox mr-2" />
                        Diapers
                      </label>
                      <label className="flex items-center mr-4 text-[14px]">
                        <input type="checkbox" name="wipes" checked={items.includes("wipes")} onClick={(e)=> {handleSupplySelect("wipes", e.target)}}  className="form-input checkbox mr-2" />
                        Wipes
                      </label>
                      <label className="flex items-center mr-4 text-[14px]">
                        <input type="checkbox" name="clothes" checked={items.includes("clothes")} onClick={(e)=> {handleSupplySelect("clothes", e.target)}}  className="form-input checkbox mr-2" />
                        Clothes
                      </label>
                    </div>
                    <div>
                      <label className="flex items-center mr-4 text-[14px]">
                        <input type="checkbox" name="water bottle" checked={items.includes("water bottle")} onClick={(e)=> {handleSupplySelect("water bottle", e.target)}}  className="form-input checkbox mr-2" />
                        Water bottles
                      </label>
                      <label className="flex items-center mr-4 text-[14px]">
                        <input type="checkbox" name="soap" checked={items.includes("soap")} onClick={(e)=> {handleSupplySelect("soap", e.target)}}  className="form-input checkbox mr-2" />
                        Soap
                      </label>
                      <label className="flex items-center mr-4 text-[14px]">
                        <input type="checkbox" name="medication" checked={items.includes("medication")} onClick={(e)=> {handleSupplySelect("medication", e.target)}}  className="form-input checkbox mr-2" />
                        Medication
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col items-start md:flex-row md:items-center mt-6">
                <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-left md:text-right md:w-1/3 w-1/6">Description</h4>
                <div className="w-full md:w-2/3 md:pl-6">
                  <textarea name="description" defaultValue={formData["description"]} onChange={(e)=> onFormChange("description", e.target.value)} className="w-full border-2 p-4 focus:outline focus:outline-[#012063]"></textarea>
                </div>
              </div>
              <div className="w-full flex flex-col items-start md:flex-row md:items-center mt-6">
                <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-left md:text-right md:w-1/3 w-full">Other items</h4>
                <div className="w-full md:w-2/3 md:pl-6">
                  <textarea name="others" defaultValue={formData["others"]} onChange={(e)=> onFormChange("others", e.target.value)} className="w-full border-2 p-4 focus:outline focus:outline-[#012063]"></textarea>
                </div>
              </div>


              <div className="mt-10 flex h-12 w-full">
                <div className="hidden md:block w-1/6 md:w-1/3"></div>
                <div className="w-full md:w-2/3 md:pl-6">
                  <button className="flex items-center justify-center text-white w-full md:w-[200px] h-full font-bold rounded bg-gradient-to-b from-[#0096D1] to-[#012063]">
                    {loading ? <CircleLoader /> : "Send"}
                  </button>
                </div>
              </div>
            </div>
            <div className="hidden md:flex justify-start md:justify-center mt-2 md:mt-0 md:ml-6 w-full md:w-[200px] md:w-1/3">
              <img
                src="/img/stationery.svg"
                alt="login"
                className="w-40 h-40 mx-auto"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
