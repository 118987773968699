import "../../assets/css/studentRegistration.css";
import { useState } from "react";
import axios from "axios";
import { CircleLoader } from "../CircleLoader";
import Swal from "sweetalert2";
import "react-toastify/dist/ReactToastify.css";
import { defaultUrl } from "../../features/actions";
import ParentForm from "./ParentForm";
import { IParentForm } from "./ParentForm";
import { makeFetchRequest } from "../../utilities/common";

export const Second = ({
  parentDs,
  setParentDs,
  emergencyDs,
  setEmergencyDs,
  handleSubmit,
  handleChange,
  nextStep,
  loading,
  setLoading,
  form,
}: any) => {
  const [validateLoading, setValidateLoading] = useState<
    "idle" | "loading" | "error"
  >("idle");
  const [existingParents, setExistingParents] = useState<any[]>([]);
  const [isValidating, setIsValidating] = useState(false);

  interface IParentExist {
    status: boolean;
  }
  const [isParentExist, setIsParentExist] = useState<IParentExist[]>([
    { status: false },
  ]);

  const [selectedParent, setSelectedParent] = useState<any>({});
  const [newExistingData, setNewExistingData] = useState([[{ email: "" }]]);
  const [newSelected, setNewSelected] = useState([]);
  const [parentId, setParentId] = useState<string[]>([]);
  const [parentIdArray, setParentIdArray] = useState([]);

  const [newParent, setNewParent] = useState<IParentForm[]>([
    {
      email: "",
      name: "",
      phone: 0,
      phone_number: "",
      home_number: "",
      relationship: "",
    },
  ]);

  const handleNewParentChange = (
    index: any,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let data: IParentForm[] = [...newParent];

    (data[index] as any)[event.target.name] = event.target.value;
    setSelectedParent({});

    if (data[0].phone && event.target.name === "phone") {
      handleChange({
        name: "phone_number",
        value: data[0].phone,
      });
    }

    if (data[index].email && event.target.name === "email") {
      let parentStatus: any[] = [...isParentExist];
      handleChange({
        name: "email",
        value: event.target.value,
      });

      setTimeout(async () => {
        setValidateLoading("loading");
        setIsValidating(true);
        const existingParent: any[] = await checkForExistingParent(
          event.target.value
        );

        // console.log("checking if parent exist", existingParent);
        if (existingParent.length > 0) {
          (parentStatus[index] as any).status = true;
          // console.log(existingParent[0].phone, existingParent[0].email);

          handleChange({
            name: "email",
            value: existingParent[0].email,
          });

          handleChange({
            name: "phone_number",
            value: existingParent[0].phone,
          });

          const newData = newExistingData.map((item: any, i: any) => {
            if (index === i) {
              return existingParent;
            } else {
              return item;
            }
          });
          setNewExistingData(newData);
        } else {
          (parentStatus[index] as any).status = false;
        }

        setIsParentExist(parentStatus);
        setValidateLoading("idle");
      }, 1500);
    }
    setExistingParents([]);
    setNewParent(data);
  };

  // console.log('selected Id', newSelected)

  const addFields = () => {
    let newFields = {
      email: "",
      name: "",
      phone: 0,
      phone_number: "",
      home_number: "",
      relationship: "",
    };

    let newIsParent = { status: false };
    let newExistParent: any = [{ email: "" }];
    setNewParent([...newParent, newFields]);
    setIsParentExist([...isParentExist, newIsParent]);
    setNewExistingData([...newExistingData, newExistParent]);
  };

  const handleNewParentSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);
    const existingEmail = newExistingData.map((item) => item[0]?.email);
    const newParentToCreate = newParent.filter(
      (item) => !existingEmail.includes(item?.email)
    );

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    if (newParentToCreate.length > 0) {
      try {
        axios
          .post(
            `${defaultUrl}/parents/create`,
            {
              data: newParentToCreate,
            },

            {
              headers: headers,
            }
          )
          .then((res) => {
            // console.log(res);
            let newExistingParentIds = newSelected;
            window.sessionStorage.setItem(
              "parenIds",
              JSON.stringify([
                ...newExistingParentIds,
                ...res.data.data.parent_id,
              ])
            );
            return [...newExistingParentIds, ...res.data.data.parent_id];
          })
          .then((parentIDs) => {
            // console.log(parentIDs);
            handleSubmit(parentIDs);
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            // console.log("err", error)
            // console.log(
            //   error.response.data.errors.email?.map((err: any) => {
            //     return err;
            //   })
            // );
            console.log(error)
            Swal.fire({
              title: `Error!`,
              text: `${error?.response?.data?.errors?.email?.map((err: any) => {
                return err;
              })}` || error?.response?.data?.message,
              icon: "error",
              showCancelButton: true,
              cancelButtonColor: "red",
              cancelButtonText: "Try Again",
            });
          });
      } catch (err: any) {
        setLoading(false);
        // console.log({ errInReg: err.response.data || err });
      }
    } else {
      handleSubmit(newSelected);
    }
  };

  const removeNewParent = (index: any) => {
    let data = [...newParent];
    let existingParentData = [...newExistingData];
    existingParentData.splice(index, 1);
    data.splice(index, 1);
    setNewParent(data);
    setNewExistingData(existingParentData);
  };

  const token = sessionStorage.getItem("token")?.replace(/['"]+/g, "");

  async function checkForExistingParent(email: string) {
    const result = await makeFetchRequest(`${defaultUrl}/parents?email=${email}`, false, true)
    setExistingParents(result);
    return result;
  }


  return (
    <>
      <div className="indicator">
        <div onClick={() => nextStep(1)} className="firstInd">
          1
        </div>
        <div onClick={() => nextStep(2)} className="firstInd">
          2
        </div>
        <div onClick={() => nextStep(3)} className="firstInd">
          3
        </div>
      </div>
      <div className="regStudentForm">
        <form action="">
          <div>
            <div>
              <div className={`flex flex-col items-center`}>
                <h2>Parent / Guardian</h2>
              </div>
              <p>{}</p>
              {newParent.map((item, index) => {
                return (
                  <ParentForm
                    key={index}
                    {...item}
                    onChange={(event: any) => handleNewParentChange(index, event)}
                    onAction={() => removeNewParent(index)}
                    index={index}
                    parentId={parentId}
                    parentIdArray={parentIdArray}
                    setParentIdArray={setParentIdArray}
                    validateLoading={validateLoading}
                    existingParents={existingParents}
                    selectedParent={selectedParent}
                    setSelectedParent={setSelectedParent}
                    isParentExist={isParentExist}
                    newExistingData={newExistingData}
                    newSelected={newSelected}
                    setNewSelected={setNewSelected}
                  />
                );
              })}

            </div>
            <div className="flex flex-col md:flex-row gap-4 justify-center items-center">
              {newParent.length < 2
              &&
              <button
              type="button"
              className="px-3 py-2 rounded text-white bg-gradient-to-b from-[#0096d1] to-[#012063]"
              onClick={() => addFields()}
            >
              Add New Parent / Guardian
            </button>
              }

              <button
                className="px-3 py-2 rounded text-white bg-gradient-to-b from-[#0096d1] to-[#012063]"
                id={"enrolButton"}
                onClick={(e) => handleNewParentSubmit(e)}
              >
                {loading ? (
                  <div className="flex gap-3 items-center">
                    <CircleLoader /> Registering Pupil...
                  </div>
                ) : (
                  "Submit Enrollment"
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
