
import { useEffect, useState } from "react";
import "../assets/css/main.css";
import { useAppSelector } from "../app/hooks";
import { defaultUrl } from "../features/actions";
import { makeFetchRequest } from "../utilities/common";

interface TopAdminNavProp {
  title: string;
  name?: string;
}

export const TopAdminNav = ({ title, name }: TopAdminNavProp) => {
  const data = JSON.parse(sessionStorage.getItem("userData") as string);
  const user = JSON.parse(sessionStorage.getItem("user") as string);

  let userStored = useAppSelector((state:any)=> state.login.data)
  let displayPicStaff = user ? user.data[0]?.profile_picture : userStored.data[0]?.profile_picture;



  const [pic, setPic] = useState(displayPicStaff);
  const [schoolLogo, setSchoolLogo] = useState(null)

  async function getSchoolProfile() {
    const response = await makeFetchRequest(`${defaultUrl}/school`)
    setSchoolLogo(response?.school_logo);
  }


  useEffect(()=>{

    if(data?.role === "super_admin"){
      getSchoolProfile()
    }
    if (data?.role !== "super_admin") {
      setPic(displayPicStaff);
    } else {
      setPic(schoolLogo);
    }

  // eslint-disable-next-line
  }, [schoolLogo])

  return (
    <div className="nav !mb-2 !pr-2">
      <p>{title}</p>

      <div>

        <button className="cursor-default">
          <div className="rounded-full h-[55px] w-[55px] overflow-hidden">
            <img style={{height: '100%', width: '100%'}} src={pic} alt="logo" />
          </div>
          <span className="text-xs max-w-max">{data?.name}</span>
        </button>
      </div>
    </div>
  );
};
