import "../../assets/css/studentRegistration.css";
import axios from "axios";
import { useState, useEffect, useMemo } from "react";
import countryList from "react-select-country-list";
import { FaTimes } from "react-icons/fa";
import { AiOutlinePlus } from "react-icons/ai";
import { defaultUrl } from "../../features/actions";

export const First = ({
  validateLoading,
  setValidateLoading,
  validateIndex,
  setValidateIndex,
  parentDs,
  setParentDs,
  emergencyDs,
  setEmergencyDs,
  handleSubmit,
  handleChange,
  nextStep,
  form,
}: any) => {
  const [getClass, setGetClass] = useState([]);
  window.sessionStorage.removeItem("parentIdd");
  const token = sessionStorage.getItem("token")?.replace(/['"]+/g, "");

  function queryClassrooms() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    // GET ALL CLASSROOMS FROM BACKEND
    return axios
      .get(`${defaultUrl}/classroom`, {
        headers: headers,
      })

      .then((res) => {
        // console.log(res.data);
        setGetClass(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    queryClassrooms();
  }, []);


  const options = useMemo(() => countryList().getData(), []);



  const removeContact = (idx: number) => {
    setEmergencyDs((contact: any) => {
      const newContact = contact.filter(
        (item: any, index: number) => index !== idx
      );
      // console.log({newContact});
      return [...newContact];
    });
  };

  const handleEmergencyChange = async (index: number, { target }: any) => {
    const { name, value, files } = target;

    setEmergencyDs((contact: any) => {
      if (files?.length > 0) {
        contact[index][name] = files[0];
      } else {
        contact[index][name] = value;
      }
      console.log({ contact });
      return [...contact];
    });
  };

  return (
    <>
      <div className="indicator">
        <div onClick={() => nextStep(1)} className="firstInd">
          1
        </div>
        <div onClick={() => nextStep(2)} className="firstInd2">
          2
        </div>
        <div onClick={() => nextStep(3)} className="firstInd2">
          3
        </div>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          nextStep();
        }}
        className="regStudentForm"
      >
        <div>
          <h2>Pupil</h2>

          <div className="studentFormContent">
            <h4>Name</h4>
            <div className="nameForm">
              <input
                type="text"
                placeholder="First name"
                name="firstname"
                value={form?.firstname}
                onChange={({ target })=> handleChange(target)}
                required
              />
              <input
                type="text"
                placeholder="Surname"
                name="surname"
                value={form?.surname}
                onChange={({ target })=> handleChange(target)}
                required
              />
              <input
                type="text"
                placeholder="Other Names"
                name="othername"
                value={form?.othername}
                onChange={({ target })=> handleChange(target)}
              />
            </div>
          </div>
          <div className="studentFormContent">
            <h4>Gender</h4>
            <div className="genderForm">
              <span>
                <input
                  type="radio"
                  name="gender"
                  value="male"
                  onChange={({ target })=> handleChange(target)}
                  checked={form?.gender === "male"}
                  required
                />{" "}
                Boy
              </span>
              <span>
                <input
                  type="radio"
                  name="gender"
                  value="female"
                  onChange={({ target })=> handleChange(target)}
                  checked={form?.gender === "female"}
                  required
                />{" "}
                Girl
              </span>
            </div>
          </div>

          <div className="studentFormContent">
            <h4>Date of Birth</h4>
            <div className="nameForm">
              <input
                type="date"
                name="date_of_birth"
                value={form?.date_of_birth}
                onChange={({ target })=> handleChange(target)}
                required
              />
            </div>
          </div>
          <h2 className="font-medium text-center">Class Enrollment</h2>
          <div className="studentFormContent">
            <h4>Class</h4>
            <div className="nameForm">
              <select
                name="classroom_id"
                value={form.classroom_id}
                onChange={({ target })=> handleChange(target)}
                required
              >
                <option value="">Select class</option>
                {getClass.map((user: any, idx: number) => {return (
                    <option key={user?.id} value={user?.id}>{user?.name}</option>
                )})}
              </select>
            </div>
          </div>

          <div className="studentFormContent">
            <h4>Admission Date</h4>
            <div className="nameForm">
              <input
                type="date"
                name="admission_date"
                value={form?.admission_date}
                onChange={({ target })=> handleChange(target)}
              />
            </div>
          </div>

          <h2>Address</h2>
          <div className="">
            <div className="studentFormContent">
              <h4>Address</h4>
              <div className="nameForm">
                <input
                  type="text"
                  placeholder="No/Street"
                  name="house_address"
                  value={form.house_address}
                  onChange={({ target })=> handleChange(target)}
                  required
                />
                <div className="nameFormDiv">
                  <input
                    type="text"
                    placeholder="City"
                    name="city"
                    value={form.city}
                    onChange={({ target })=> handleChange(target)}
                    required
                  />
                  <input
                    type="text"
                    placeholder="State"
                    name="state"
                    value={form.state}
                    onChange={({ target })=> handleChange(target)}
                    required
                  />
                </div>
                <select name="country" onChange={({ target })=> handleChange(target)} required id="">
                  <option value="" >Select Country</option>
                  <option value="Nigeria" >Nigeria</option>
                  {options?.map((country: any, idx:number) => {
                    return (
                     <option key={country.label} value={country.label}>{country.label}</option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="studentFormContent">
              <h4>ZIP / Postal Code</h4>
              <div className="zip">
                <input
                  type="text"
                  placeholder="Code"
                  name="zip_code"
                  value={form.zip_code}
                  onChange={({ target })=> handleChange(target)}
                />
              </div>
            </div>
            <div className="studentFormContent">
              <h4>Address 2</h4>
              <div className="nameForm">
                <textarea name="" id="" placeholder="Optional"></textarea>
              </div>
            </div>
          </div>
          <button className="studentRegBtn">Next step</button>
        </div>
      </form>
    </>
  );
};
