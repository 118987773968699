import { FaTimes } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectRe_RenderedState } from "../../features/sharedStateSlices/shareState";
import { makeFetchRequest } from "../../utilities/common";
import { defaultUrl } from "../../features/actions";
import loader from "../../assets/loader.gif";

export const AddParentForm =({numberOfParents=0, form, addParent, handleParentSubmit, handleParentChange}:any)=> {
  const reloaded = useSelector(state => selectRe_RenderedState(state))
  const [existingParents, setExistingParents] = useState<any>()
  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState(false)

  async function checkForExistingParent(email: string) {
    setExistingParents(null)
    setLoading(true)
    const result = await makeFetchRequest(`${defaultUrl}/parents?email=${email}`, false, true)
    if(result && result[0]?.email){
      setExistingParents(result[0]);
    }
    setLoading(false)

  }



  useEffect(() => {

  },[reloaded, existingParents])

    return (
        <div className="regStudentForm">
        <form onSubmit={(e: any) => {
                handleParentSubmit(e, existingParents)}
                }
          action="">
          <div>
            {form.map((parent:any, idx:number) => (
              <div key={idx}>
                <div>
                    <FaTimes
                        onClick={()=> addParent((prev:any)=> {
                            return prev.filter((item:any, indx:number)=> idx !== indx)
                        })}
                        className={`border text-red-600 h-8 w-8 m-1 p-1 mx-auto ${idx === 0 && "hidden"}`}
                    />
                </div>
                <h2>Parent / Guardian {idx + 1 + numberOfParents}</h2>
                {!selected  &&
                <div className="studentFormContent">
                <h4>Email</h4>
                <div className="nameForm">
                  <input
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={parent?.email}
                    onChange={(event)=> {
                      checkForExistingParent(event.target.value)
                      handleParentChange(idx, event);
                    }}
                    required
                  />

              {loading
               &&
               <div className={`flex items-center`}>
               <div className="h-[20px] w-[20px] mr-2">
                 <img src={loader} alt="loader" />
               </div>
               <p className="text-xs">checking if parent exists..</p>
             </div>
               }
                </div>

              </div>
                }


                {/* AUTO SELECT PARENT */}
                {existingParents &&
              (<div className="px-5 sm:px-10 my-4">
                <b className="text-[10px] text-green-800">Parent Found</b>
                <p>
                <b>{existingParents?.name}
                    <span>
                    <button
                          className="rounded bg-green-800 text-white text-xs py-2 px-4 m-2"
                          onClick={(e) => {
                            e.preventDefault();
                            setSelected(true)
                          }}
                        >
                          {selected
                            ? "Selected!"
                            : "Click to Select"}
                        </button>
                    </span>
                     </b>
                </p>


                </div>)
            }

                {/* AUTO SELECT PARENT */}


                {!selected  &&
                (<>
                                <div className="studentFormContent">
                  <h4>Name</h4>
                  <div className="nameForm">
                    <input
                      type="text"
                      placeholder="Full Name"
                      name="name"
                      value={parent?.name}
                      onChange={(event)=> {
                        handleParentChange(idx, event);
                      }}
                      required
                    />
                  </div>
                </div>
                <div className="studentFormContent">
                  <h4>Mobile Number</h4>
                  <div className="nameForm">
                    <input
                      type="tel"
                      placeholder="Phone Number"
                      name="phone"
                      value={parent?.phone}
                      onChange={(event)=> {
                        handleParentChange(idx, event);
                      }}
                      required
                    />
                  </div>
                </div>
                <div className="studentFormContent">
                  <h4>Other Phones</h4>

                  <div className="phoneOption">
                    <input
                      type="tel"
                      placeholder="Home"
                      name="home_number"
                      value={parent?.home_number}
                      onChange={(event)=> handleParentChange(idx, event)}
                    />
                    <input
                      type="tel"
                      placeholder="Home"
                      name="work_number"
                      value={parent?.work_number}
                      onChange={(event)=> handleParentChange(idx, event)}
                    />
                  </div>
                </div>
                <div className="studentFormContent">
                  <h4>Relation</h4>
                  <div className="genderForm">
                    <span>
                      <input
                        type="radio"
                        name={`relationship_${idx}`}
                        value="Parent"
                        checked={parent.relationship === "Parent"}
                        onChange={(event)=> handleParentChange(idx, {target: {name: "relationship", value: event.target.value}})}
                      />
                      Parent
                    </span>
                    <span>
                      <input
                        type="radio"
                        name={`relationship${idx}`}
                        value="Guardian"
                        checked={parent?.relationship === "Guardian"}
                        onChange={(event)=> handleParentChange(idx, {target: {name: "relationship", value: event.target.value}})}
                      />
                      Guardian
                    </span>
                  </div>
                </div>
                </>)
}
              </div>
            ))}



                      {/* Only display the Add new Parent if you don't
                       have any parents in the student collection */}
            {numberOfParents < 1 && <div className="addButton">
              <button
                type="button"
                onClick={() => {
                    addParent((previousData:any)=> [...previousData, {
                        name: "",
                        email: "",
                        phone: "",
                        home_number: "",
                        work_number: "",
                        relationship: "",
                    }])
                }}
              >
                Add New Parent / Guardian
              </button>
            </div>}

          </div>
          <button
            type="submit"
            className="studentRegBtn"
            onClick={()=>{}}
          >
            {"Save Changes"}
          </button>
        </form>
      </div>
    )
}
