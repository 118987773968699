import { createSlice, createSelector } from '@reduxjs/toolkit';

type State = {
    isRendered: boolean,
};
const initialState : State ={
    isRendered: false,
}

const sharedSlice = createSlice({
  name: 'shared_useEffect',
  initialState,
  reducers: {
    setSharedState: (state) => {
        state.isRendered =  !state.isRendered
    },


  },
});


export const selectA = (state : any) => state.shared_useEffect;   //shared_useEffect from reducer name

//check shared_useEffect value which is setSharedState
export const selectRe_RenderedState = createSelector([selectA], x => x.isRendered)



export const { setSharedState } = sharedSlice.actions;
export default sharedSlice.reducer;
